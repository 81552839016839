import Dayjs from '@/dayjs';
import { generateId } from '@/utils/generateId';
import type { MessageUpdateReason, Paginator } from '@twilio/conversations';
import { Client, Conversation, Message, Participant } from '@twilio/conversations';
import { ReplayEventEmitter } from '@twilio/replay-event-emitter';
import { debounce, find, map, uniqBy } from 'lodash';
import { marked } from 'marked';
import type { ConnectionState } from 'twilsock';

export type ApendayChatEvents = {
    ready: () => void;
    connectionStateChanged: (state: ConnectionState) => void;
    messagesUpdated: (data: { messages: ChatMessage[]; updateReasons?: MessageUpdateReason[] }) => void;
    groupedByUserMessagesUpdated: (data: { messages: ChatMessage[][]; updateReasons?: MessageUpdateReason[] }) => void;
    conversationClosed: () => void;
    error: (error: string | Error) => void;
    tokenExpired: () => void;
    typingStarted: () => void;
    typingEnded: () => void;
    unreadMessagesCountUpdated: (count: number) => void;
};

export default class ApendayChat extends ReplayEventEmitter<ApendayChatEvents> {
    interaction: Interaction | Session;
    userTwilioToken: string;
    userNickname: string;
    userEmail: string;
    meTwilio: Participant | undefined;
    client: Client;
    conversation: Conversation;
    participants: Participant[];
    activePage: Paginator<Message>;
    sentMessages: Message[] = [];
    waitingMessages: ChatMessage[] = [];
    messages: ChatMessage[] = [];
    groupedByUserMessages: ChatMessage[][] = [];

    constructor({ userTwilioToken, interaction, userNickname, userEmail }: InteractionInstanceConfig) {
        super();
        if (!userTwilioToken?.length || !interaction || !userNickname?.length || !userEmail?.length) {
            console.error("Can't init ApendayVideo instance, user twilio token, interaction, userNickname or userEmail missing");
            return;
        }
        this.interaction = interaction;
        this.userTwilioToken = userTwilioToken;
        this.userNickname = userNickname;
        this.userEmail = userEmail;
        this.initClient();
    }

    initClient() {
        this.client = new Client(this.userTwilioToken);
        this.client.on('initialized', async () => {
            try {
                await this.initConversation();
                await this.fetchMe();
                await this.fetchMessages();
                await this.emitMessages();
                this.updateUnreadMessagesCount();
                this.emit('ready');
            } catch (e) {
                this.handleError(e);
            }
        });

        this.client.on('initFailed', ({ error }) => {
            console.log('initFailed', error);
            this.handleError(error);
        });
        this.client.on('connectionError', (error) => {
            console.log('connectionError');
            console.error(error);
        });
        this.client.on('connectionStateChanged', (state) => {
            this.emit('connectionStateChanged', state);
            if (state === 'connected') this.handleError(null);
        });
        this.client.on('tokenAboutToExpire', () => {
            console.log('tokenAboutToExpire');
            this.client.updateToken(this.userTwilioToken);
        });
        this.client.on('tokenExpired', () => {
            console.log('tokenExpired');
            this.emit('tokenExpired');
        });
    }

    async initConversation() {
        if (this.interaction.status === 'in_progress') {
            this.conversation = await this.client.getConversationBySid(this.interaction.twilio_id);
        } else {
            this.conversation = await this.client.peekConversationBySid(this.interaction.twilio_id);
        }

        this.conversation.on('messageAdded', (message) => this.onMessageAdded(message));
        this.conversation.on('messageUpdated', (data) => this.onMessageUpdated(data));
        this.conversation.on('messageRemoved', (message) => this.onMessageRemoved(message));
        this.conversation.on('participantJoined', (participant) => {
            console.log('participantJoined : ', participant.identity);
            this.fetchParticipants();
        });
        this.conversation.on('participantUpdated', ({ participant, updateReasons }) => {
            console.log('participantUpdated : ', participant.identity, updateReasons);
            this.fetchParticipants();
            if (updateReasons.includes('lastReadTimestamp')) this.updateUnreadMessagesCount();
        });
        this.conversation.on('participantLeft', (participant) => {
            console.log('participantLeft : ', participant.identity);
            this.fetchParticipants();
        });
        this.conversation.on('typingStarted', () => this.emit('typingStarted'));
        this.conversation.on('typingEnded', () => this.emit('typingEnded'));
        this.conversation.on('updated', ({ updateReasons, conversation }) => {
            if (updateReasons.includes('state') && conversation.state?.current === 'closed') this.emit('conversationClosed');
        });

        if (this.conversation.state?.current === 'closed') {
            this.emit('conversationClosed');
        }
    }

    async fetchParticipants() {
        this.participants = await this.conversation.getParticipants();
    }

    async fetchMe() {
        await this.fetchParticipants();
        this.meTwilio = find(this.participants, (o) => o.identity === this.userEmail);
    }

    async fetchMessages(pageSize = 1000) {
        this.activePage = await this.conversation.getMessages(pageSize);
        if (this.activePage?.items?.length) this.sentMessages = this.activePage.items;
    }

    async onMessageAdded(message: Message) {
        // console.log('messageAdded', message.index);
        this.sentMessages.push(message);
        await this.emitMessages();
        this.updateUnreadMessagesCount();
    }

    async onMessageUpdated({ message, updateReasons }: { message: Message; updateReasons: MessageUpdateReason[] }) {
        console.log('onMessageUpdated', message);
        // TODO find and replace updated message
        await this.emitMessages(updateReasons);
    }

    async onMessageRemoved(message: Message) {
        console.log('onMessageRemoved', message);
        await this.emitMessages();
    }

    async emitMessages(updateReasons?: MessageUpdateReason[]) {
        let array: ChatMessage[];
        array = map(this.sentMessages, (message) => this.getChatMessage(message));
        array.push(...this.waitingMessages);
        array = uniqBy(array, (o) => o.id);
        array.sort((a, b) => {
            if (Dayjs(a.addDate).isBefore(Dayjs(b.addDate))) return -1;
            else if (Dayjs(a.addDate).isAfter(Dayjs(b.addDate))) return +1;
            return 0;
        });
        // eslint-disable-next-line no-undef
        array = array.filter(
            (m) =>
                m.participantSid ||
                m.widget === (import.meta.env.VITE_APP === 'live') ||
                m.admin === (import.meta.env.VITE_APP === 'admin'),
        );
        this.messages = this.handleMarkdown(array);
        this.groupedByUserMessages = this.getGroupedByUserMessages(this.messages);

        this.emit('messagesUpdated', { messages: this.messages, updateReasons });
        this.emit('groupedByUserMessagesUpdated', { messages: this.groupedByUserMessages, updateReasons });
    }

    handleMarkdown(messages: ChatMessage[]) {
        return messages.map((message) => {
            return {
                ...message,
                ...(message.nickname && { body: marked.parse(message.body) }),
            } as ChatMessage;
        });
    }

    getGroupedByUserMessages(messages: ChatMessage[]): ChatMessage[][] {
        const grouped: ChatMessage[][] = [];
        let userArray: ChatMessage[] = [];
        messages.forEach((message, index) => {
            userArray.push(message);
            if (this.messages.length === index + 1) {
                grouped.push(userArray);
            } else if (this.messages[index + 1].participantSid !== message.participantSid) {
                grouped.push(userArray);
                userArray = [];
            }
        });

        return grouped;
    }

    async shutdown() {
        await this.client.shutdown();
    }

    async addMessage({ message }: { message: string }): Promise<number> {
        const messageObj: ChatMessage = {
            id: generateId(34),
            participantSid: this.meTwilio?.sid ?? '',
            nickname: this.userNickname,
            body: message,
            addDate: Dayjs().toDate(),
            sendSuccess: false,
            owner: true,
        };
        this.waitingMessages.push(messageObj);
        await this.emitMessages();

        // TODO - If chatbot enabled, send message to chatbot to apenday server

        return this.sendMessage(messageObj);
    }

    async sendMessage(message: ChatMessage): Promise<number> {
        return await this.conversation.sendMessage(message.body, {
            id: message.id,
            nickname: message.nickname,
            addDate: message.addDate,
        });
    }

    getChatMessage(message: Message): ChatMessage {
        return {
            index: message.index,
            id: message.attributes.id ?? message.sid,
            participantSid: message.participantSid ?? undefined,
            body: message.body ?? '',
            nickname: message.participantSid ? message.attributes.nickname ?? this.interaction.user_identity : undefined,
            addDate: message.attributes.addDate ?? message.dateCreated,
            sendSuccess: true,
            owner: message.participantSid === this.meTwilio?.sid,
            widget: message.attributes.widget ?? false,
            admin: message.attributes.admin ?? false,
        };
    }

    handleError(error: any) {
        if (error) console.error(error);
        try {
            this.emit('error', error.message ?? error);
        } catch (e) {
            null;
        }
    }

    typing() {
        this.conversation?.typing();
    }

    async setAllMessagesRead(): Promise<number> {
        if (this.conversation?.status === 'joined') {
            return await this.conversation.setAllMessagesRead();
        } else {
            return 0;
        }
    }

    updateUnreadMessagesCount() {
        if (this.conversation.lastReadMessageIndex === null) return;
        let count = 0;
        if (this.messages.length && this.conversation) {
            const lastMessage = this.messages[this.messages.length - 1];
            if (lastMessage.index) {
                count = lastMessage.index - this.conversation.lastReadMessageIndex;
            }
        }
        this.emitUnreadMessagesCount(count);
    }

    emitUnreadMessagesCount = debounce((count) => {
        this.emit('unreadMessagesCountUpdated', count);
    }, 500);

    updateToken(userTwilioToken: string) {
        this.userTwilioToken = userTwilioToken;
    }
}
