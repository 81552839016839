import Axios, { activeAbortControllers } from '@/axios';
import { GetItemsPayload, RootState } from '@/store';
import { generateId } from '@/utils/generateId';
import { ActionContext } from 'vuex';

export interface ReportingState {
    dashboard: DashboardReporting | null;
    meeting: MeetingReporting | null;
    summary: SummaryStats | null;
    consultantReporting: ConsultantStats | null;
    schedulerReporting: ConsultantStats | null;
    agencyReporting: ConsultantStats | null;
    satisfaction: SatisfactionPageStats | null;
    interaction: InteractionPageStats | null;
    session: SessionPageStats | null;
    interactionConsultant: InteractionConsultantStats | null;
    sessionConsultant: SessionConsultantStats | null;
}

export const reporting = {
    namespaced: true,
    state: (): ReportingState => ({
        dashboard: null,
        meeting: null,
        summary: null,
        consultantReporting: null,
        schedulerReporting: null,
        agencyReporting: null,
        satisfaction: null,
        interaction: null,
        session: null,
        interactionConsultant: null,
        sessionConsultant: null,
    }),
    mutations: {
        updateDashboard: (state: ReportingState, dashboardReporting: DashboardReporting) => {
            state.dashboard = dashboardReporting;
        },
        updateMeeting: (state: ReportingState, meetingReporting: MeetingReporting) => {
            state.meeting = meetingReporting;
        },
        updateSummary: (state: ReportingState, summaryStats: SummaryStats) => {
            state.summary = summaryStats;
        },
        updateConsultants: (state: ReportingState, consultantStats: ConsultantStats) => {
            state.consultantReporting = consultantStats;
        },
        updateAgency: (state: ReportingState, agencyStats: ConsultantStats) => {
            state.agencyReporting = agencyStats;
        },
        updateSatisfaction: (state: ReportingState, satisfactionStats: SatisfactionPageStats) => {
            state.satisfaction = satisfactionStats;
        },
        updateInteraction: (state: ReportingState, interactionStats: InteractionPageStats) => {
            state.interaction = interactionStats;
        },
        updateSession: (state: ReportingState, sessionStats: SessionPageStats) => {
            state.session = sessionStats;
        },
        updateScheduler: (state: ReportingState, schedulerStats: ConsultantStats) => {
            state.schedulerReporting = schedulerStats;
        },
        updateInteractionConsultant: (state: ReportingState, interactionConsultantStats: InteractionConsultantStats) => {
            state.interactionConsultant = interactionConsultantStats;
        },
        updateSessionConsultant: (state: ReportingState, sessionConsultantStats: SessionConsultantStats) => {
            state.sessionConsultant = sessionConsultantStats;
        },
    },
    actions: {
        async getDashboardStats({ commit }: ActionContext<ReportingState, RootState>, { params, callId }: GetItemsPayload) {
            const controller = new AbortController();
            const id = callId ?? generateId();
            activeAbortControllers[id] = controller;
            const data = await Axios.request({
                method: 'get',
                url: '/api/private/stats/dashboard',
                signal: controller.signal,
                params,
            });
            commit('updateDashboard', data);
            return data;
        },
        async getSummaryStats({ commit }: ActionContext<ReportingState, RootState>, { params, callId }: GetItemsPayload) {
            const controller = new AbortController();
            const id = callId ?? generateId();
            activeAbortControllers[id] = controller;
            const data = await Axios.request({
                method: 'get',
                url: '/api/private/stats/summary',
                signal: controller.signal,
                params,
            });
            commit('updateSummary', data);
            return data;
        },
        async getMeetingStats({ commit }: ActionContext<ReportingState, RootState>, { params, callId }: GetItemsPayload) {
            const controller = new AbortController();
            const id = callId ?? generateId();
            activeAbortControllers[id] = controller;
            const data = await Axios.request({
                method: 'get',
                url: '/api/private/stats/scheduled-meetings',
                signal: controller.signal,
                params,
            });
            commit('updateMeeting', data);
            return data;
        },
        async getConsultantReporting({ commit }: ActionContext<ReportingState, RootState>, { params, url, callId }: GetItemsPayload) {
            const controller = new AbortController();
            const id = callId ?? generateId();
            activeAbortControllers[id] = controller;
            const data = await Axios.request({
                method: 'get',
                url: url ?? '/api/private/stats/consultants',
                signal: controller.signal,
                params,
            });
            commit('updateConsultants', data);
            return data;
        },
        async getSchedulerReporting({ commit }: ActionContext<ReportingState, RootState>, { params, url, callId }: GetItemsPayload) {
            const controller = new AbortController();
            const id = callId ?? generateId();
            activeAbortControllers[id] = controller;
            const data = await Axios.request({
                method: 'get',
                url: url ?? '/api/private/stats/scheduler',
                signal: controller.signal,
                params,
            });
            commit('updateScheduler', data);
            return data;
        },
        async getAgencyReporting({ commit }: ActionContext<ReportingState, RootState>, { params, url, callId }: GetItemsPayload) {
            const controller = new AbortController();
            const id = callId ?? generateId();
            activeAbortControllers[id] = controller;
            const data = await Axios.request({
                method: 'get',
                url: url ?? '/api/private/stats/agencies',
                signal: controller.signal,
                params,
            });
            commit('updateAgency', data);
            return data;
        },
        async getSatisfactionStats({ commit }: ActionContext<ReportingState, RootState>, { params, callId }: GetItemsPayload) {
            const controller = new AbortController();
            const id = callId ?? generateId();
            activeAbortControllers[id] = controller;
            const data = await Axios.request({
                method: 'get',
                url: '/api/private/stats/satisfaction',
                signal: controller.signal,
                params,
            });
            commit('updateSatisfaction', data);
            return data;
        },
        async getInteractionStats({ commit }: ActionContext<ReportingState, RootState>, { params, callId }: GetItemsPayload) {
            const controller = new AbortController();
            const id = callId ?? generateId();
            activeAbortControllers[id] = controller;
            const data = await Axios.request({
                method: 'get',
                url: '/api/private/stats/live',
                signal: controller.signal,
                params,
            });
            commit('updateInteraction', data);
            return data;
        },
        async getSessionStats({ commit }: ActionContext<ReportingState, RootState>, { params, callId }: GetItemsPayload) {
            const controller = new AbortController();
            const id = callId ?? generateId();
            activeAbortControllers[id] = controller;
            const data = await Axios.request({
                method: 'get',
                url: '/api/private/stats/assist',
                signal: controller.signal,
                params,
            });
            commit('updateSession', data);
            return data;
        },
        async getInteractionConsultantStats({ commit }: ActionContext<ReportingState, RootState>, { params, callId }: GetItemsPayload) {
            const controller = new AbortController();
            const id = callId ?? generateId();
            activeAbortControllers[id] = controller;
            const data = await Axios.request({
                method: 'get',
                url: '/api/private/stats/live/consultant',
                signal: controller.signal,
                params,
            });
            commit('updateInteractionConsultant', data);
            return data;
        },
        async getSessionConsultantStats({ commit }: ActionContext<ReportingState, RootState>, { params, callId }: GetItemsPayload) {
            const controller = new AbortController();
            const id = callId ?? generateId();
            activeAbortControllers[id] = controller;
            const data = await Axios.request({
                method: 'get',
                url: '/api/private/stats/assist/consultant',
                signal: controller.signal,
                params,
            });
            commit('updateSessionConsultant', data);
            return data;
        },
    },
};
