import { activeCommitHash } from '@/env/active-commit-hash';
import { activeEnv } from '@/env/active-env';

interface Features {
    notificationsCenter: boolean;
}

interface EnvConfig {
    envName: 'production' | 'preprod' | 'integration' | 'docker' | 'local';
    releaseName: string;
    baseUrl: string;
    liveBaseUrl: string;
    assistBaseUrl: string;
    maintenance: boolean;
    features: Features;
}

interface EnvConfigs {
    [key: string]: EnvConfig;
}

const envConfigs: EnvConfigs = {
    prod: {
        envName: 'production',
        baseUrl: 'https://app.apenday.com',
        liveBaseUrl: 'https://live.apenday.com',
        assistBaseUrl: 'https://assist.apenday.com',
        features: {
            notificationsCenter: false,
        },
        maintenance: false,
        releaseName: 'prod_' + activeCommitHash,
    },
    preprod: {
        envName: 'preprod',
        baseUrl: 'https://preprod.app.apenday.com',
        liveBaseUrl: 'https://preprod.live.apenday.com',
        assistBaseUrl: 'https://preprod.assist.apenday.com',
        features: {
            notificationsCenter: false,
        },
        maintenance: false,
        releaseName: 'preprod_' + activeCommitHash,
    },
    integration: {
        envName: 'integration',
        baseUrl: 'https://int.app.apenday.com',
        liveBaseUrl: 'https://int.live.apenday.com',
        assistBaseUrl: 'https://int.assist.apenday.com',
        features: {
            notificationsCenter: false,
        },
        maintenance: false,
        releaseName: 'integration_' + activeCommitHash,
    },
    docker: {
        envName: 'docker',
        baseUrl: 'http://localhost:3000',
        liveBaseUrl: 'http://localhost:3005',
        assistBaseUrl: 'http://localhost:3006',
        features: {
            notificationsCenter: false,
        },
        maintenance: false,
        releaseName: 'docker_' + activeCommitHash,
    },
    local: {
        envName: 'local',
        baseUrl: 'http://localhost:3000',
        liveBaseUrl: 'http://localhost:3005',
        assistBaseUrl: 'http://localhost:3006',
        features: {
            notificationsCenter: false,
        },
        maintenance: false,
        releaseName: 'local_' + activeCommitHash,
    },
};

export const getEnv = (): EnvConfig => {
    return envConfigs[activeEnv];
};
