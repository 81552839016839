import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface AiAssistantState {
    aiAssistant: AiAssistant | null;
    aiAssistants: AiAssistants | null;
    aiAssistantOptions: AiAssistants | null;
}

export const aiAssistant = merge(getBaseStore('aiAssistant'), {
    actions: {
        async createAiAssistant({ commit, state }: ActionContext<AiAssistantState, RootState>) {
            commit('updateAiAssistant', {
                name: '',
                initialPrompt: '',
                area: '',
            });

            return state.aiAssistant;
        },
    },
});
