export const getPlural = (name) => {
    const dictionary = {
        agency: 'agencies',
        meeting: 'meetings',
        group: 'groups',
        user: 'users',
        scheduledMeeting: 'scheduledMeetings',
        area: 'areas',
        constraint: 'constraints',
        availability: 'availabilities',
        unavailability: 'unavailabilities',
        userCategory: 'userCategories',
        offer: 'offers',
        globalConfig: 'globalConfigs',
        hub: 'hubs',
        smtpSetting: 'smtpSettings',
        smsSetting: 'smsSettings',
        supportThread: 'supportThreads',
        supportMessage: 'supportMessages',
        page: 'pages',
        ftpExport: 'ftpExports',
        scheduledMeetingTag: 'scheduledMeetingTags',
        appEvent: 'appEvents',
        invite: 'invites',
        areaCrm: 'areaCrms',
        areaCrmMeeting: 'areaCrmMeetings',
        areaCrmField: 'areaCrmFields',
        externalCrmField: 'externalCrmFields',
        interaction: 'interactions',
        widget: 'widgets',
        widgetAvailability: 'widgetAvailabilities',
        closingPeriod: 'closingPeriods',
        session: 'sessions',
        dataSource: 'dataSources',
        userDefaultAvailability: 'userDefaultAvailabilities',
        brevoApiSetting: 'brevoApiSettings',
        aiAssistant: 'aiAssistants',
    };

    return dictionary[name] ?? name;
};
