export default {
    branding: {
        appName: 'Apenday',
        slogan: 'The business meeting solution',
    },
    menu: {
        dashboard: 'Dashboard',
        meeting: {
            dashboard: 'Rendez-vous',
            hub: 'Pré-qualification',
            list: 'Événements',
            scheduledMeeting: {
                forthcoming: 'Rendez-vous planifiés',
                forthcomingShort: 'Planifiés',
                past: 'Rendez-vous en cours',
                pastShort: 'Passés',
                history: 'Historique',
                historyShort: 'Historique',
            },
        },
        config: {
            config: 'Configuration',
            offer: 'Offres',
            offerDetail: "Détail de l'offre",
            area: 'Espace client',
            globalConfig: 'Configuration générale',
            constraints: 'Contrainte des champs',
            pages: 'Tutoriels',
            dataSource: 'Source de données',
        },
        settings: {
            settings: 'Paramètres',
            user: 'Utilisateurs',
            group: 'Équipes',
            agency: 'Agences',
            userCategory: 'Services et fonctions',
            scheduledMeetingTag: 'Tags',
            appEvent: 'Notifications',
        },
        reporting: {
            reporting: 'Statistiques',
            book: 'Book',
            live: 'Live',
            assist: 'Assist',
        },
        notifications: 'Notifications',
        help: {
            title: "Besoin d'aide ?",
            firstSteps: 'Premiers pas',
            tutorials: 'Tutoriels',
            assistance: 'Assistance',
            feedbacks: 'Feedbacks',
        },
        user: {
            myProfile: 'Mon compte',
            news: 'Nouveautés',
            notifications: 'Notifications',
            killImpersonate: 'Quitter le mode espion',
            logOut: 'Déconnexion',
        },
        live: {
            live: 'Live',
        },
        assist: {
            assist: 'Assist',
        },
        session: {
            dashboard: 'Apenday Assist',
            inProgress: 'Sessions en cours',
            closed: 'Historique des Sessions',
        },
        interaction: {
            dashboard: 'Apenday Live',
            pending: 'Interactions en attente',
            inProgress: 'Interactions en cours',
            closed: 'Historique des interactions',
        },
        widget: {
            list: 'Widgets',
        },
    },
    // Entity
    agency: {
        entity: {
            area: 'Espace client',
            name: 'Nom',
            address: 'Adresse',
            postal_code: 'CP',
            city: 'Ville',
            administrative_area: 'Département',
            latitude: 'Lat',
            longitude: 'long',
            lat_long: 'Coordonnées',
            short_name: 'Nom court',
            short_nameHelper: 'Cette valeur limitée à 30 caractères peut être utilisée dans les notifications SMS',
            phone_number: 'Numéro de téléphone',
            external_identifier: 'Identifiant externe',
            raw_managed_postal_codes: "Liste des codes postaux gérés par l'agence",
            raw_managed_postal_codesHelper:
                "Un code postal par ligne. Si l'utilisateur sélectionne un code postal sur la préqualification, l'agence gérant son code postal sera présélectionnée.\n" +
                'Pour que ce paramètre soit pris en compte, activez l\'option "Demander le code postal" sur vos pages de préqualification.',
            raw_managed_physical_postal_codes: "Liste des codes postaux gérés par l'agence uniquement pour les rendez-vous physiques",
            raw_managed_physical_postal_codesHelper: 'Un code postal par ligne',
        },
        listing: {
            pageTitle: 'Agences',
            noResults: 'Pas de résultats',
        },
        edit: {
            pageTitle: 'Agences',
        },
        import: {
            pageTitle: 'Importer des agences',
        },
    },
    crm: {
        entity: {
            triggers: {
                override_area: "Surcharger les préférences de l'espace client",
                meeting_confirmed: 'Rendez-vous confirmé',
                meeting_updated: 'Rendez-vous modifié',
                meeting_canceled: 'Rendez-vous annulé',
                meeting_missed: 'Rendez-vous manqué',
                meeting_finished: 'Rendez-vous terminé',
                survey_validated: 'Enquête de satisfaction validée',
            },
            fields: {
                override_area: "Surcharger les préférences de l'espace client",
                last_name: 'Nom de famille',
                phone_number: 'Téléphone',
                first_name: 'Prénom',
                email: 'Email',
            },
        },
        edit: {
            CrmTriggers: {
                pageTitle: 'Déclencheurs',
                pageSubTitle: 'Sélectionner les déclencheurs pour automatiser les actions souhaitées.',
            },
            CrmFields: {
                pageTitle: 'Correspondance des champs',
                pageSubTitle: 'Sélectionner les valeurs à intégrer sur votre CRM',
                noResult: 'Aucun champ à configurer...',
                apendayField: 'Champ Apenday',
                crmField: 'Correspondance du champ ({ value })',
            },
            CrmContent: {
                pageTitle: 'Liste CRMs',
            },
        },
    },
    area: {
        entity: {
            name: 'Nom',
            enabled: 'Actif',
            offer: 'Offre',
            offer_service: 'Services',
            serviceOptions: {
                assist_enabled: 'Assist',
                book_enabled: 'Book',
                live_enabled: 'Live',
            },
            slug: 'Url',
            responsable_name: 'Nom responsable',
            created_at: 'Date de création',
            events_organizer_email_address: "Adresse email de l'organisateur des événements",
            default_timezone: 'Fuseau horaire par défaut',
            allow_timezone_change: 'Donner la possibilité aux utilisateurs de changer de fuseau horaires.',
            theme: 'Thème',
            themeOptions: {
                halo: 'Halo',
                bgcolor: 'Flat Design',
            },
            primary_color: 'Couleur primaire',
            secondary_color: 'Couleur secondaire',
            show_powered_by: 'Afficher le logo de la marque',
            powered_by_logo_style: 'Style du logo',
            powered_by_logo_styleOptions: {
                normal: 'Normal',
                white: 'Blanc',
            },
            label_a_consultant: 'Label pour "un conseiller". Exemple d\'utilisation : "Trouver un conseiller"',
            label_agencies: 'Label pour "agences"',
            label_agency: 'Label pour "agence"',
            label_an_agency: 'Label pour "une agence"',
            label_consultant: 'Label pour "conseiller"',
            label_consultants: 'Label pour "conseillers"',
            label_of_agencies: 'Label pour "des agences". Exemple d\'utilisation : "Tous les conseillers des agences de Paris"',
            label_of_agency: 'Label pour "de l\'agence"',
            label_of_consultant: 'Label pour "du conseiller". Exemple d\'utilisation : "L\'agenda du conseiller"',
            label_of_consultants: 'Label pour "des conseillers". Exemple d\'utilisation : "Les agendas des conseillers"',
            label_some_agencies: 'Label pour "des agences". Exemple d\'utilisation : "Faire le tour des agences"',
            label_some_consultants: 'Label pour "des conseillers". Exemple d\'utilisation : "Trouver des conseillers"',
            label_the_agencies: 'Label pour "les agences"',
            label_the_agency: 'Label pour "l\'agence"',
            label_the_consultant: 'Label pour "le conseiller". Exemple d\'utilisation : "Contacter le conseiller"',
            label_the_consultants: 'Label pour "les conseillers". Exemple d\'utilisation : "Tous les conseillers"',
            label_meeting_type_other: 'Label pour "Autre" dans la configuration de l\'événement',
            allow_video_meetings: "Activer l'option visioconférence",
            video_provider: 'Système de visio',
            twillio_account_sid: 'Twillio account SID',
            twillio_api_key_sid: 'Twillio API key',
            twillio_api_key_secret: 'Twillio API Secret',
            video_providerOptions: {
                twillio: 'Twillio',
                teams: 'Teams',
                meet: 'Meet',
            },
            email: 'Configuration E-mailing',
            sms_config: 'Configuration SMS',
            cookie_banner_content: 'Contenu du bandeau cookie',
            cookie_policy: 'Politique des cookies',
            privacy_policy: 'Politique de confidentialité',
            cookie_banner_contentHelper: 'Si ce champs est laissé vide, la configuration générale sera utilisée',
            cookie_policyHelper: 'Si ce champs est laissé vide, la configuration générale sera utilisée',
            privacy_policyHelper: 'Si ce champs est laissé vide, la configuration générale sera utilisée',
            logo: 'Logo',
            logoHelper: "Format minimum recommandé : 340 de largeur ou 240 de hauteur selon l'orientation",
            visio_logo: 'Logo pour la visio conférence',
            visio_logoHelper: "Format minimum recommandé : 340 de largeur ou 240 de hauteur selon l'orientation",
            visio_background: 'Arrière plan pour la visio conférence',
            visio_backgroundHelper: 'Format recommandé : 1280x720',
            support_phone_number: 'Instructions de contact support',
            support_phone_numberHelper: "Affiché en cas d'absence d'agences à proximité",
            body_background_color: "Couleur d'arrière plan",
            calendar_active_cell_color: 'Couleur des cases actives du calendrier',
            allowed_consultant_cancel_scheduled_meeting: 'Autoriser {0} à annuler ses rendez-vous',
            allowed_consultant_update_scheduled_meeting: 'Autoriser {0} à modifier ses rendez-vous',
            allowed_group_manager_meeting_edition: "Autoriser les responsables d'équipes à modifier les événements",
            allowed_group_manager_hub_edition: "Autoriser les responsables d'équipes à modifier les pages de préqualifications",
            allowed_group_manager_stats_of_team_read: "Autoriser les responsables d'équipes à consulter les statistiques de ses équipes",
            allowed_group_manager_user_edition: "Autoriser les responsables d'équipes à modifier les utilisateurs",
            allowed_group_manager_availability_edition: "Autoriser les responsables d'équipes à modifier les disponibilités",
            enabled_occupancy_alerts: "Activer les alertes sur les taux d'occupations",
            occupancy_alerts_recipient_roles: 'Niveau utilisateur à notifier',
            occupancy_alerts_percent: 'Seuil de déclenchement',
            occupancy_alerts_period_duration: 'Période surveillée',
            twilio_conversation_sid: 'Twilio conversation SID',
            twilio_auth_token: 'Twilio Auth Token',
            assist_invite_text: "Message SMS/Email envoyé pour l'invitation",
            assist_invite_text_placeholder: "Bonjour, vous êtes invité à participer à une session d'assistance.",
            assist_invite_text_placeholder_vars: {
                consultant_full_name: 'Nom complet du conseiller',
            },
            assist_invite_text_helper: 'Laissez vide pour utiliser le message par défaut',
            live_privacy_policy_file: 'Fichier PDF pour la politique de confidentialité',
            live_privacy_policy_url: 'Url pour la politique de confidentialité',
            assist_config: {
                default_camera: 'Caméra utilisateur par défaut',
                default_cameraOptions: {
                    front: 'Frontale',
                    back: 'Arrière',
                },
            },
            solution: {
                book: 'Book',
                assist: 'Assist',
                live: 'Live',
            },
            vonage_number: 'Numéro Vonage',
            vonage_emitter_number: "Numéro affiché émetteur de l'appel",
            vonage_api_key: 'Clé API Vonage',
            vonage_api_secret: 'Clé secrète API Vonage',
            scim_provisioning_enabled: 'Activer le provisionning SCIM',
            scim_provisioning_enabledHelper: "Une fois activé, retrouvez la clé API dans l'onglet : Technique > Approvisionnement SCIM",
            send_account_created_notification: "Envoyer une notification lors de l'activation des comptes utilisateurs",
            enabled_calendar_alerts: 'Activer les alertes agenda',
            enabled_calendar_alerts_helper: "Recevez des alertes lorsque les agendas connectés ne sont plus inaccessibles l'outil",
            calendar_alerts_recipient_roles: 'Niveau utilisateur à notifier',
        },
        wording: {
            label_a_consultant: 'le conseiller',
            label_agencies: 'agences',
            label_agency: 'agence',
            label_an_agency: 'une agence',
            label_consultant: 'Conseiller',
            label_consultants: 'Conseillers',
            label_of_agencies: 'des agences',
            label_of_agency: "de l'agence",
            label_of_consultant: 'du conseiller',
            label_of_consultants: 'des conseillers',
            label_some_agencies: 'des agences',
            label_some_consultants: 'des conseillers',
            label_the_agencies: 'les agences',
            label_the_agency: "l'agence",
            label_the_consultant: 'le conseiller',
            label_the_consultants: 'les conseillers',
            label_meeting_type_other: 'Autre',
        },
        listing: {
            pageTitle: 'Espace client',
        },
        modal: {
            reset: {
                title: 'Réinitialisation espace client',
                delete_users: 'Supprimer les utilisateurs',
                delete_meetings: 'Supprimer les événements',
                delete_availabilities: 'Supprimer les disponibilités',
                delete_scheduled_meetings: 'Supprimer les rendez-vous',
                confirmResponse: {
                    header: 'Succès',
                    message: `Utilisateurs supprimés : {deleted_users}<br>Événements supprimés : {deleted_meetings}<br>Disponibilités supprimés : {deleted_availabilities}<br>Rendez-vous supprimés : {deleted_scheduled_meetings}`,
                },
            },
        },
        edit: {
            pageTitle: 'Espace client',
            general: {
                subMenuLabel: 'Général',
                pageTitle: 'Configuration générale',
                events_organizer_email_addressHelper:
                    "Adresse email utilisée dans les fichiers ics envoyés aux utilisateurs. Si vide, l'adresse email du conseiller sera utilisée.\n",
                slugHelper:
                    "Ce champs sera utilisé comme préfixe d'url pour le événements de cet espace client. Il ne peut contenir que des caractères alpha numériques et des tirets. Si vous le laissez vide il sera généré automatiquement à partir du nom de l'espace client.",
            },
            appearance: {
                subMenuLabel: 'Apparence',
                logo: {
                    pageTitle: 'Logo',
                },
                theme: {
                    pageTitle: 'Thème',
                },
                wording: {
                    pageTitle: 'Vocabulaire',
                },
            },
            technical: {
                subMenuLabel: 'Technique',
                pageTitle: 'Technique',
                visio: {
                    pageTitle: 'Configuration Live / Assist',
                    twilio: 'Configuration Twilio',
                },
                email: {
                    pageTitle: 'Configuration E-mailing',
                    createAlert: {
                        header: 'Quel service de messagerie souhaitez-vous utiliser ?',
                        smtpSetting: 'Smtp',
                        brevoApiSetting: 'Brevo',
                    },
                },
                sms: {
                    pageTitle: 'Configuration SMS',
                },
                ftpExport: {
                    pageTitle: 'Exports FTP',
                },
                purge: {
                    pageTitle: 'Purge des données',
                },
                scim: {
                    pageTitle: 'Approvisionnement SCIM',
                    scim_provisioning_token: 'Clé API',
                    scim_endpoint: 'Point de terminaison',
                    toastSuccess: 'Clé régénérée avec succès',
                    scim_infos:
                        "Avec SCIM, vous pouvez gérer les utilisateurs directement depuis votre fournisseur d'identité, ce qui facilite l'intégration et le départ des employés à grande échelle.",
                    scim_infos_link: "Plus d'informations",
                },
                aiAssistant: {
                    pageTitle: 'Assistant IA',
                },
            },
            notifications: {
                subMenuLabel: 'Notifications {0}',
                pageTitle: 'Notifications',
            },
            advanced: {
                subMenuLabel: 'Avancé',
                wording: {
                    subMenuLabel: 'Wording',
                    pageTitle: 'Wording',
                    assist_session_end_message: 'Message de fermeture de session',
                },
                rights: {
                    subMenuLabel: 'Droits',
                    pageTitle: 'Droits',
                },
                occupancy: {
                    subMenuLabel: 'Alerte occupation',
                    pageTitle: 'Alerte occupation',
                    occupancy_alerts_percentHelper: "Pourcentage d'occupation au-delà duquel l'alerte se déclenche",
                    occupancy_alerts_period_durationHelper: 'Période en jour surveillée',
                },
                calendar: {
                    subMenuLabel: 'Alerte agenda',
                    pageTitle: 'Alerte accessibilité agenda',
                    pageIntro: 'Notification quotidienne – Accessibilité des agendas',
                    pageInfoBox: `<p><strong>Fréquence : </strong>Du lundi au vendredi à 8h.</p>
                    <br>
                    <p><strong>Objectif : </strong>Informer les utilisateurs concernés lorsque des agendas sont inaccessibles malgré des disponibilités sur des événements actifs.</p>
                    <br>
                    <p><strong>Conseiller</strong></p>
                    <p>Recevez une alerte si votre agenda est inaccessible alors que vous avez des disponibilités sur des événements actifs. </p>
                    <br>
                    <p><strong>Responsable d'équipe</strong></p>
                    <p>Soyez informé si un membre de votre équipe ayant des disponibilités sur des événements actifs a un agenda inaccessible. </p>
                    <br>
                    <p><strong>Administrateur</strong></p>
                    <p>Recevez une alerte si des utilisateurs de votre organisation ayant des disponibilités sur des événements actifs ont un agenda inaccessible.</p>`,
                },
                closingPeriod: {
                    pageTitle: 'Fermetures exceptionnelles',
                },
                satisfaction: {
                    subMenuLabel: 'Satisfaction',
                    pageTitle: 'Enquête de satisfaction Assist',
                },
            },
            crm: {
                subMenuLabel: 'CRM',
                pageTitle: 'Configuration CRM',
                customize:
                    'Personnaliser la configuration depuis chaque événement : les déclencheurs et les correspondances des champs personnalisés',
                salesforce: {
                    pageTitle: 'SalesForce',
                    connectedBy: 'Connecté par { value }',
                },
            },
            satisfaction: {
                satisfactionSurveyQuestionPlaceholder: 'Comment évaluez-vous la qualité de notre service ?',
            },
        },
    },
    constraint: {
        entity: {
            area: 'Espace client',
            label: 'Nom',
            type: 'Type',
            value: 'Valeur',
        },
        listing: {
            pageTitle: 'Contrainte des champs',
            noResults: 'Pas de résultats',
        },
        edit: {
            pageTitle: 'Contrainte des champs',
            valueHelper: 'Dans le cas de valeurs multiples, les séparer par des ","',
            typeHelper:
                "Type de contrainte. Voir cette page pour plus d'informations : https://vee-validate.logaretm.com/v4/guide/global-validators#vee-validaterules",
        },
        typeList: {
            alpha: 'Caractères alphabétiques ou chiffres (alpha)',
            alpha_dash: 'Caractères alphabétiques, des chiffres, des tirets ou des traits de soulignement (alpha_dash)',
            alpha_num: 'Caractères alphabétiques ou chiffres (alpha_num)',
            alpha_spaces: 'Caractères alphabétiques ou espaces (alpha_spaces)',
            digits: 'Numérique (digits)',
            email: 'Email (email)',
            integer: 'Entier valide (integer)',
            length: 'Longueur (length)',
            max: 'Quantité max (max)',
            max_value: 'Valeur maximum (max_value)',
            min: 'Quantité minimum (min)',
            min_value: 'Valeur minimum (min_value)',
            numeric: 'Chiffres (numérique)',
            regex: 'Expression régulière (regex)',
            ext: 'Extensions spécifiées (ext)',
            required: 'Requis (required)',
            file_size: 'Taille fichier (file_size)',
        },
    },
    globalConfig: {
        entity: {
            name: 'Nom technique',
            label: 'Nom',
            value: 'Valeur',
        },
        listing: {
            pageTitle: 'Configuration générale',
        },
        edit: {
            pageTitle: 'Configuration générale',
        },
    },
    group: {
        entity: {
            area: 'Espace client',
            name: 'Nom',
            users: 'Utilisateurs',
            parent: 'Équipe parente',
        },
        listing: {
            pageTitle: 'Équipes',
            noResults: 'Pas de résultats',
            orphans: 'Orphelins',
        },
        edit: {
            pageTitle: 'Équipes',
        },
    },
    hub: {
        entity: {
            enabled: 'Actif',
            area: 'Espace client',
            groups: 'Équipes',
            name: 'Nom',
            meeting: 'Événements',
            welcome_text: "Texte d'accueil",
            description: 'Description',
            choices: 'Option',
            enabled_postal_code_option: 'Demander le code postal',
            postal_code_field_placeholder: 'Texte dans le champ du code postal',
            postal_code_field_placeholder_placeholder: "ex: Indiquer le code postal du lieux d'exercice",
            message: 'Message',
        },
        listing: {
            pageTitle: 'Page de préqualification',
            noResults: 'Pas de résultats',
        },
        edit: {
            pageTitle: 'Page de préqualification',
            addChoice: 'Ajouter une option',
            addSubChoice: 'Ajouter un sous-motif',
            addMessage: 'Ajouter un message',
            postalCodeTooltip: `Si cette option est activée, l'utilisateur pourra renseigner son code postal lorsqu'il sélectionne un événement ayant la sélection d'agence activée.
                            Après cette étape, l'agence en charge de ce code postal sera présélectionnée (pré requis : les codes postaux doivent être renseignés au niveau de l'agence).`,
            optionTooltip:
                "Saisissez le motif du rendez-vous puis sélectionnez les événements correspondants. L'identification des types proposés est automatique. A vous de définir l'ordre de d'affichage des types de canaux.",
            hubChoiceType: {
                meeting: 'Événements',
                message: 'Message',
            },
        },
        publish: {
            pageTitle: 'Publier',
            informations: {
                subMenuLabel: 'Informations',
                pageTitle: 'Url de prise de rendez-vous',
            },
            iframe: {
                subMenuLabel: 'Intégrer en Iframe',
                pageTitle: 'Intégrer en Iframe',
                showLolo: 'Afficher votre logo',
                preview: 'Aperçu',
                helper: "Placez ce code à l'endroit où vous souhaitez intégrer l'application.",
            },
            link: {
                subMenuLabel: 'Intégrer un lien',
                pageTitle: 'Intégrer un lien',
                linkLabel: 'Prenez rendez-vous',
                helper: "Placez ce code à l'endroit où vous souhaitez insérer un lien.",
            },
            popIn: {
                subMenuLabel: 'Intégrer en pop-in',
                pageTitle: 'Intégrer en pop-in',
                helper: "Placez ce code à l'endroit où vous souhaitez intégrer le bouton.",
            },
        },
    },
    meeting: {
        entity: {
            enabled: 'Actif',
            enabledFilterLabel: 'Statut',
            enabledOptions: {
                enabled: 'Actif',
                disabled: 'Inactif',
            },
            area: 'Espace client',
            groups: 'Équipes',
            name: 'Nom',
            short_name: 'Nom court',
            technical_name: 'Nom technique',
            welcome_text_app: "Texte d'accueil",
            description: 'Descriptif',
            type: 'Type',
            typeOptions: {
                default: 'Physique',
                video: 'Visioconférence',
                call: 'Téléphone',
                other: 'Autre',
            },
            agency_selection_enabled: 'Activer la sélection d’agence',
            allowed_empty_agency_selection: 'Autoriser l’utilisateur à ne pas choisir d’agence',
            allowed_agency_filtering: "Autoriser l'utilisateur à filtrer les agences",
            hidden_agency_address: "Masquer l'adresse des agences sur la page de sélection et le récapitulatif",
            allowed_override_of_preassigned_agency: "Autoriser l'utilisateur à modifier l'agence qui lui a été attribuée automatiquement",
            agency_not_available_message: "Message à afficher lorsque l'agence correspondant au code postal n'est pas disponible",
            enabled_agency_geolocation: 'Activer la géolocalisation des agences',
            show_week_ends: 'Afficher les week ends sur le calendrier',
            duration: 'Durée',
            min_planification_delay: 'Délai de planification minimum',
            min_delay_between: 'Délai entre deux rendez-vous',
            max_schedule_per_day: 'Planification max. par jour et par conseiller',
            min_update_delay: 'Délai min. avant modification',
            allowed_agency_change: "Autoriser l'utilisateur à changer d'agence",
            allowed_consultant_change: "Autoriser l'utilisateur à changer de conseiller",
            min_cancellation_delay: 'Délai min. avant annulation',
            max_update_number: 'Modification max.',
            updatable: 'Événement modifiable',
            cancellable: 'Événement annulable',
            update_policy: 'Politique de modification',
            update_policy_shown_in_confirm_email: "Afficher dans l'email de confirmation",
            update_policy_shown_in_confirm_page: 'Afficher sur la page de confirmation',
            update_policy_shown_in_external_calendar: 'Afficher sur les événements de calendrier',
            update_policy_shown_in_remind_email: "Afficher sur l'email de rappel",
            cancellation_policy: 'Politique d’annulation',
            cancellation_policy_shown_in_confirm_email: "Afficher dans l'email de confirmation",
            cancellation_policy_shown_in_confirm_page: 'Afficher sur la page de confirmation',
            cancellation_policy_shown_in_external_calendar: 'Afficher sur les événements de calendrier',
            cancellation_policy_shown_in_remind_email: "Afficher sur l'email de rappel",
            limited_schedules: 'Limiter le nombre de prise de rendez-vous',
            limited_schedules_period: 'Période',
            limited_schedules_number: 'Nombre de rendez-vous autorisés sur la période',
            limited_schedules_error_message: "Message d'erreur en cas de dépassement",
            consultant_selection_policy: 'Mode d’attribution',
            consultant_selection_policy_short: 'Configuration',
            consultant_selection_policy_options: {
                by_user: 'Choix utilisateur',
                by_priority: 'Par priorité',
                by_regular_dispatch: 'Uniforme',
                mixed: 'Optimisé',
                by_team: 'Collectif',
            },
            fallback_consultant_selection_policy: "Mode d'attribution en cas de non préférence",
            allowed_empty_consultant_selection: "Autoriser l'utilisateur à ne pas choisir {value}",
            allowed_consultant_filtering: "Autoriser l'utilisateur à filtrer la liste {value}",
            show_consultant_job: 'Afficher la fonction {value}',
            team_members: 'Membres de l’équipe par ordre de priorité',
            limited_schedules_identifier_field: 'Identifiant unique',
            enabled_satisfaction_survey: "Activer l'enquête de satisfaction",
            satisfaction_survey_max_score: 'Score maximal',
            satisfaction_survey_limit_for_alert: "Note limite pour l'envoi d'alerte email",
            satisfaction_survey_alert_recipients: 'Destinataire(s) des alertes email',
            satisfaction_survey_allow_comment: "Autoriser l'utilisateur à écrire un commentaire",
            satisfaction_survey_question: 'Question',
            limited_schedules_statuses: 'Statut des rendez-vous concernés',
            allow_extra_attendees: "Permettre d'inviter d'autres personnes lors de la prise de rendez-vous",
            invite_only: 'Rendez-vous disponibles uniquement sur invitation',
            mode: 'Configuration',
            modes: {
                one_to_one: 'Individuel',
                team: 'Collectif',
                group: 'Groupe',
            },
            capacity: 'Capacité',
            show_remaining_capacity_on_calendar: 'Afficher la capacité restante sur le calendrier',
            purge_helper: "Si un champ n'est pas rempli on ne gère pas la purge",
            max_delay_before_schedule: 'Durée de la période ouverte à la réservation',
            max_delay_before_schedule_helper:
                'Si vous définissez 30 jours, les rendez-vous ne seront proposés que pour les 30 prochains jours. Laisser 0 pour ne pas limiter la période.',
            meeting_delay_before_anonymization: 'Durée avant anonymisation',
            meeting_delay_before_anonymization_helper:
                'Les données de rendez vous sont conservées et accessibles sur votre espace cependant les champs contenants des données personnelles sont anonymisés',
            meeting_delay_before_archiving: 'Durée avant archivage',
            meeting_delay_before_archiving_helper:
                'Toutes les données de rendez-vous sont anonymisées. Les données ne sont plus accessibles sur votre espace (Export et Listing) cependant, elles sont comptabilisées pour les statistiques',
            meeting_delay_before_deletion: 'Durée avant suppression',
            meeting_delay_before_deletion_helper: 'Toutes les données sont supprimées : les données de rendez-vous et les statistiques',
            live_delay_before_message_deletion: 'Durée de vie des conversations',
            live_delay_before_message_deletion_helper: 'Les données personnelles et conversations sont supprimées',
            live_delay_before_archiving: 'Durée avant archivage',
            live_delay_before_archiving_helper:
                'Toutes les données sont anonymisées et non accessibles. Cependant, elles sont comptabilisées dans les statistiques',
            live_delay_before_deletion: 'Durée avant suppression',
            live_delay_before_deletion_helper: 'Toutes les données sont supprimées : les données de sessions et les statistiques',
            assist_delay_before_record_deletion: 'Durée de vie des médias',
            assist_delay_before_record_deletion_helper: 'Les médias collectés lors des sessions sont supprimés',
            assist_delay_before_archiving: 'Durée avant archivage',
            assist_delay_before_archiving_helper:
                "(plus de médias et plus de données perso mais reste dans les stats) Les données de sessions d'assistance et les médias sont supprimés cependant elles sont comptabilisées pour les statistiques",
            assist_delay_before_deletion: 'Durée avant suppression',
            assist_delay_before_deletion_helper: 'Toutes les données sont supprimées : les données des sessions et les statistiques',
            allowed_tags: "Tags associés à l'événement",
            require_tag_on_missed_scheduled_meeting: 'Tag obligatoire pour les rendez-vous manqués',
            require_tag_on_terminated_scheduled_meeting: 'Tag obligatoire pour les rendez-vous terminés',
            satisfaction_max_delay: "Délai de réponse à l'enquête de satisfaction",
            satisfaction_max_delay_helper: 'Laisser 0 pour ne pas limiter la période',
            no_availability_were_foun_error_message: "Message en cas d'absence de rendez-vous disponible",
            no_availability_were_foun_error_message_placeholder: "Désolé, il n'y a pas de rendez-vous disponible.",
            consultant_notification_enabled: "Notifier le conseiller dès qu'une évaluation est soumise",
            required_query_parameters_validation: "Valider la signature des paramètres de l'URL",
            required_cancellation_reason: "Rendre obligatoire le motif d'annulation",
        },
        dashboard: {
            pageTitle: 'Prise de Rendez-vous',
        },
        listing: {
            pageTitle: 'Événements',
            noResults: 'Pas de résultats',
            groupNameLabel: '{value} pers.',
            groupNameLabelFull: '{value} personnes',
        },
        edit: {
            pageTitle: 'Événements',
            configuration: {
                subMenuLabel: 'Configuration',
                general: {
                    pageTitle: 'Configuration générale',
                    url: 'Url',
                    short_name_placeholder: 'max. 10 caractères (notification SMS)',
                    welcomeTextAppTooltip: "Invitez à la prise de rendez-vous en ajoutant un texte d'accueil.",
                    shortNameTooltip:
                        'Cette valeur pourra être réutilisée pour personnaliser vos notifications SMS. Elle est limitée à 10 caractères.',
                    slug: "Ce champ est utilisé pour générer l'URL de votre événement. Il ne peut contenir que des caractères alphanumériques et des tirets. Si vous le laissez vide, il sera généré automatiquement à partir du nom de l'événement.",
                    min_planification_delay: 'Définissez un délai de planification pour éviter les rendez-vous de dernière minute !',
                    allowedAgencyFilteringHelper:
                        "Si cette case est cochée l'utilisateur pourra filtrer la liste des agences par département.",
                    enabledAgencyGeolocationHelper:
                        "Si cette case est cochée, l'utilisateur pourra chercher les agences les plus proches de lui grâce à une carte.",
                    hiddenAgencyAddressHelper: "Si cette option est active, seul le nom de l'agence est affiché.",
                    allowedOverrideOfPreassignedAgencyHelper:
                        "Cette option ne s'applique que lorsque l'utilisateur a renseigné un code postal dans la page de préqualification avant d'arriver sur l'interface de prise de rendez-vous. Si elle est active, l'utilisateur à la possibilité de modifier le choix automatique. Si elle n'est pas activée, l'étape sélection d'agence n'est pas proposée que dans le cas où aucune agence n'a pu être attribuée automatiquement.",
                    agencyNotAvailableHelper: `Ce message d'erreur sera affiché lorsque l'utilisateur arrive d'une préqualification sur laquelle le code postal est demandé si aucune agence prenant en charge son code postal n'est disponible.`,
                },
                updateCancel: {
                    pageTitle: 'Modification & Annulation',
                    updateTitle: 'Modification client',
                    cancelTitle: 'Annulation client',
                    maxUpdateNumber:
                        "Au delà de ce nombre de modifications, l'utilisateur n'aura plus la possibilité de modifier son rendez-vous.",
                },
            },
            dynamicFields: {
                subMenuLabel: 'Formulaire',
                pageTitle: 'Champs des formulaires',
                fieldsLabel: 'Champs par ordre d’affichage',
                constraintsSelectPlaceholder: 'Sélectionnez un type de contraintes',
                optionsPlaceholder: 'Une option par ligne',
                datasourceSelectPlaceholder: 'Choisir une source',
                datasourceSelectHelper: 'Éditer les sources de données ->',
            },
            team: {
                subMenuLabel: 'Équipe',
                distribution: {
                    pageTitle: 'Distribution',
                    fallbackConsultantSelectionPolicyTooltip:
                        'Définissez le mode d’attribution dans le cas où l’utilisateur ne souhaite pas sélectionner le conseiller.',
                    swiperHelper: {
                        by_user: `Une étape supplémentaire permettra à l’utilisateur de choisir un conseiller.`,
                        by_priority: `Cette configuration permettra d’optimiser la disponibilité. Si au
                                    moins un membre de votre équipe est disponible, le créneau correspondant sera
                                    proposé à l’utilisateur. Dans le cas où plusieurs conseillers sont disponibles au
                                    créneau choisi, le rendez-vous sera affecté au membre ayant la priorité la plus
                                    élevée. Si les membres de l’équipe ont une priorité égale, le rendez-vous sera
                                    attribué à la personne ayant le moins de rendez-vous planifiés.`,
                        by_regular_dispatch: `Les événements seront répartis uniformément au sein des membres
                                    de votre équipe. Le nombre d’événements qui seront affectés à chaque conseiller sera
                                    comptabilisé. Si un conseiller se voit affecter trop de rendez-vous, sa
                                    disponibilité sera masquée jusqu’à ce que les autres membres aient atteint le même
                                    nombre de rendez-vous.`,
                        mixed: `Les événements seront répartis uniformément au sein des membres
                                    de votre équipe. Le nombre d’événements qui seront affectés à chaque conseiller sera
                                    comptabilisé. Les rendez-vous seront automatiquement affectés au conseiller ayant le moins de rendez-vous.
                                    En cas de nombre de rendez-vous égaux, ils seront attribués par priorité. Les créneaux des conseillers
                                    ayant le plus de rendez-vous ne seront pas masqués et seront tout de même proposés s'ils sont les seuls disponibles.`,
                    },
                },
                list: {
                    pageTitle: 'Liste {value}',
                    addLoadingMessage: 'Ajout en cours',
                },
                availability: {
                    pageTitle: 'Définir les disponibilités de l’équipe',
                    emptyHelper:
                        'Vous devez d\'abord constituer votre équipe avant de pouvoir définir des disponibilités. Cliquez sur "liste"',
                },
            },
            restrictions: {
                subMenuLabel: 'Restrictions',
                pageTitle: 'Restrictions',
                limitedSchedulesIdentifierFieldTooltip: 'Sélection l’identifiant …',
                limitedSchedulesErrorMessageTooltip:
                    'Ce message sera affiché à l\'utilisateur s\'il dépasse la limite du nombre de rendez-vous. Vous pouvez intégrer la prochaine date autorisée en utilisant le motif de remplacement "{date}". Ex: "Vous pourrez reprendre rendez-vous à partir du {date}", affichera "Vous pourrez reprendre rendez-vous à partir du 24/08/2020 à 10:50"',
                disclaimer:
                    'En activant cette option le nombre de prise de rendez-vous pour un même utilisateur sera limité sur la période donnée. (Ex : 1 rendez-vous maximum sur une période de 6 mois)',
                inviteOnlyHelper: 'En activant cette option, le rendez-vous ne sera disponible que sur invitation',
                requiredQueryParametersValidationTooltip:
                    "Lorsque cette option est cochés une vérification est effectuée sur les paramètres de pré-remplissage passés dans l'URL afin d'éviter qu'ils puissent être altérés par l'utilisateur",
                requiredQueryParametersValidationHelpText: 'Clé à utiliser pour la signature des paramètres :',
                requiredQueryParametersValidationHelpLink: "Comment signer les paramètres d'URL ?",
            },
            notifications: {
                subMenuLabel: 'Notifications',
                satisfactionExtraAttendees: 'Vous pouvez également notifier des personnes supplémentaires',
            },
            satisfaction: {
                subMenuLabel: 'Satisfaction',
                pageTitle: 'Enquête de satisfaction',
                satisfactionSurveyQuestionPlaceholder: 'Êtes-vous satisfait de votre rendez-vous ?',
                satisfactionSurveyLimitForAlertHelper:
                    'Ex. Si ce champs est défini à 2, la/les adresse(s) email renseignée(s) dans le champs "Destinataire(s) des alertes" sera/seront notifiée(s) par email en cas de note inférieure ou égale à 2. Si le champs reste vide aucune notification ne sera envoyée.',
            },
            crm: {
                subMenuLabel: 'CRM',
                pageTitle: 'CRM',
            },
            createPopin: {
                intro: 'Sélectionnez le type de rendez-vous que vous souhaitez créer',
                'one-to-one': {
                    title: 'Individuel',
                    subTitle: 'Un conseiller avec un participant.',
                    description:
                        'Parfait pour tous vos rendez-vous individuels. Plusieurs modes de distribution vous sont proposés : Choix utilisateur, par priorité, optimisé ou uniforme.',
                },
                team: {
                    title: 'Collectif',
                    subTitle: 'Plusieurs conseillers avec un participant.',
                    description: 'Parfait pour planifier vos rendez-vous commerciaux de groupe !',
                },
                group: {
                    title: 'Groupe',
                    subTitle: 'Un conseiller avec un groupe de participants.',
                    description:
                        'Idéal pour les sessions de formation, les ateliers d’information, les journées portes ouvertes... Permettez à plusieurs clients de vous rencontrer sur le même créneau.',
                },
            },
        },
        publish: {
            pageTitle: 'Publier',
            informations: {
                subMenuLabel: 'Informations',
                pageTitle: 'Url de prise de rendez-vous',
            },
            integration: {
                subMenuLabel: 'Intégration',
                iframe: {
                    subMenuLabel: 'Iframe',
                    pageTitle: 'Intégrer en Iframe',
                    showLogo: 'Afficher votre logo',
                    preview: 'Aperçu',
                    helper: "Placez ce code à l'endroit où vous souhaitez intégrer l'application.",
                },
                link: {
                    subMenuLabel: 'lien',
                    pageTitle: 'Intégrer un lien',
                    linkLabel: 'Prenez rendez-vous',
                    helper: "Placez ce code à l'endroit où vous souhaitez insérer un lien.",
                },
                popin: {
                    subMenuLabel: 'pop-in',
                    pageTitle: 'Intégrer en pop-in',
                    helper: "Placez ce code à l'endroit où vous souhaitez intégrer le bouton.",
                },
            },
            invite: {
                subMenuLabel: 'Partager des créneaux',
                pageTitle: 'Partager des créneaux',
                helper: 'Partagez des créneaux de disponibilité',
                modal: {
                    title: 'Partagez via E-mail',
                    segments: {
                        email: 'E-mail',
                        clipboard: 'Copier/Coller',
                    },
                    clipboardHelper: 'Copier/Collez les créneaux que vous avez choisis dans un e-mail',
                    preview: "Aperçu de l'e-mail",
                    emailHelper: 'Envoyez directement vos disponibilités par e-mail',
                },
            },
            schedule: {
                subMenuLabel: 'Prendre un rendez-vous',
                pageTitle: 'Prendre un rendez-vous',
                helper: 'Vous pouvez prendre un rendez-vous pour une personne tierce',
                formTitle: 'Informations client',
                modal: {
                    title: 'Confirmer le rendez-vous',
                },
            },
        },
        instantVisio: {
            modal: {
                pageTitle: 'Visio instantanée',
                helper: 'Transformer votre rendez-vous en visioconférence. Lancer la visio via votre lien dédié et partager le lien à votre interlocuteur par mail et ou par mobile',
                consultantLink: 'Lien {value}',
                customerLink: 'Lien client',
                sendSuccess: 'Lien envoyé avec succès',
            },
        },
    },
    offer: {
        entity: {
            name: 'Nom',
            manager_limit: 'Managers max',
            consultant_limit: 'Conseillers max',
            user_account_limit: 'Utilisateurs max',
            event_limit: 'Événements max',
            apenday_book_enabled: 'Activer Apenday Book',
            apenday_live_enabled: 'Activer Apenday Live',
            apenday_assist_enabled: 'Activer Apenday Assist',
        },
        listing: {
            pageTitle: 'Offres',
        },
        edit: {
            pageTitle: 'Offres',
        },
    },
    appEvent: {
        entity: {
            formatted: 'Nom',
            operation_type: "Type d'opération",
            subject_class: 'Sujet',
            operation_typeOptions: {
                created: 'Création',
                updated: 'Mis à jour',
                deleted: 'Suppression',
                disabled: 'Désactivation',
                enabled: 'Activation',
                cancelled: 'Annulation',
                missed: 'Manqués',
                terminated: 'Terminés',
                pending: 'En attente',
                reassigned: 'Réaffectation',
                moved: 'Déplacement',
                closed: 'Fermeture',
                imported: 'Importation',
            },
            created_at: 'Date',
        },
        listing: {
            pageTitle: 'Notifications',
        },
    },
    scheduledMeeting: {
        entity: {
            agency: 'Agence',
            area: 'Espace client',
            cancelled_by_consultant_at: 'Annulé par le conseiller le {date} à {time}',
            consultant: 'Conseiller',
            current_consultant: '{value} actuel',
            date: 'J / H',
            dynamic_field_values: 'Valeurs des champs',
            history: 'Historique',
            meeting: 'Événement',
            new_consultant: 'Nouveau {value}',
            no_new_consultant: 'Personne disponible',
            satisfaction: 'Satisfaction',
            satisfaction_comment: 'Commentaire',
            satisfaction_score: 'Note',
            status: 'Statut',
            statusOptions: {
                scheduled: 'Planifié',
                terminated: 'Terminé',
                missed: 'Manqué',
                cancelled: 'Annulé',
                cancelled_by_consultant: 'Annulé par le conseiller',
                total: 'Total',
            },
            tags: 'Tags',
            utmCampaign: 'UTM Campain',
            utmMedium: 'UTM Medium',
            utmSource: 'UTM Source',
            cancellation_reason: "Motif d'annulation",
        },
        list: {
            search: 'Rechercher',
            info: 'Informations',
            name: 'Nom',
        },
        forthcoming: {
            pageTitle: 'Rendez-vous planifiés',
        },
        past: {
            pageTitle: 'Rendez-vous en cours',
        },
        history: {
            pageTitle: 'Historique',
        },
        bulkReassign: {
            pageTitle: 'Réaffecter les rendez-vous',
            noResults: 'Veuillez patienter',
            placeholder: 'Choisir',
            modal: {
                title: 'Vous êtes sur le point de réaffecter ce rendez-vous',
                confirm: 'Confirmer la réaffectation',
            },
        },
        edit: {
            pageTitle: 'Rendez-vous',
            subPageTitle: 'Configuration du rendez-vous',
            dateLabel: 'Date du rendez-vous',
            userNotification: 'Notifier le client',
            consultantNotification: 'Notifier {value}',
            emailNotification: 'Envoyer une notification email',
            smsNotification: 'Envoyer une notification SMS',
            placeholders: {
                user: {
                    email: {
                        subject: '[Important] Votre rendez-vous "{0}" a été modifié',
                        content: `Bonjour,<br/><br/>
                        Nous sommes dans l'incapacité d'honorer votre rendez-vous planifié le {0}.
                        Nous vous proposons le {1}.
                        Nous vous invitons à nous recontacter si vous n'êtes pas disponible sur le créneau proposé.<br/><br/>
                        Bien Cordialement,`,
                    },
                    sms: {
                        content: `Bonjour, votre rendez-vous {0} du {1} a été déplacé au {2}. En cas d'indisponibilité merci de nous recontacter.`,
                    },
                },
                consultant: {
                    email: {
                        subject: 'Un rendez-vous vient de vous être réaffecté',
                        content: `Bonjour,<br/><br/>
                        Le rendez-vous {0} - {1} le {2} a été modifié.
                        <br/><br/>
                        Détail du nouveau rendez-vous : <br/>
                        Nouvelle date/heure : {3}<br/>
                        {4}
                        <br/><br/>
                        Ce dernier sera automatiquement intégré à votre agenda.<br/><br/>
                        Bien cordialement`,
                    },
                    sms: {
                        content:
                            "Le rendez-vous prévu le {0} a été modifié, voici la date et l'heure du nouveau rendez-vous {1}. Bien cordialement",
                    },
                },
            },
            availability: {
                true: 'Disponible',
                false: 'Indisponible',
            },
            action: {
                cancel: 'Annuler le rendez-vous',
            },
        },
        filters: {
            dateRange: 'Période',
        },
        listing: {
            noResults: 'Pas de résultats',
            missed_at: 'Manqué le {date} à {time}',
            cancelled_at: 'Annulé le {date} à {time}',
            closed_at: 'Fermé le {date} à {time}',
            created_at: 'Confirmation le {date} à {time}',
            scheduled_at: 'Planifié pour le {date} à {time}',
            terminated_at: 'Terminé le {date} à {time}',
            cancelled_by_consultant_at: 'Annulé par le conseiller le {date} à {time}',
            pending_at: 'En attente depuis {date} à {time}',
        },
        modal: {
            tags: {
                title: 'Tags',
                content: "Vous avez la possibilité d'associer un ou plusieurs tag(s) à ce rendez-vous.",
                required: 'Vous devez contribuer obligatoirement un tag',
            },
            terminate_group: {
                title: 'Terminer le rendez-vous',
                content: 'Vous êtes sur le point de terminer ce rendez-vous',
                accordion_title: 'Avancé',
            },
            cancel: {
                title: 'Annulation de rendez-vous',
                content: "Veuillez indiquer le motif d'annulation de ce rendez-vous.",
                placeholder: "Saisissez le motif d'annulation",
                required: "Vous devez indiquer un motif d'annulation",
            },
        },
        summary: {
            meetingName: 'RDV',
            scheduledDateTime: 'Date',
            fields: 'Infos client',
            extra_attendees: 'E-mail(s) participant(s) invité(s)',
            utm: {
                values: 'Valeurs UTM',
                utm_source: 'Source',
                utm_campaign: 'Campagne',
                utm_medium: 'Media',
            },
        },
        debug: {
            moved: 'Déplacé',
            created: 'Confirmé',
            cancelled: 'Annulé',
            terminated: 'Classé comme terminé',
            missed: 'Classé comme manqué',
            pending: 'En attente de classification',
            ipAddress: 'Adresse IP',
            scheduledMeetingDate: 'Date',
            scheduledMeetingTime: 'Heure',
            scheduledMeetingConsultant: 'Conseiller',
            scheduledMeetingUser: 'Client',
            changedProperties: 'Modifications',
        },
        alerts: {
            create: {
                sendSuccess: 'Votre rendez-vous a bien été pris !',
            },
        },
    },
    scheduledMeetingTag: {
        entity: {
            label: 'Nom',
            area: 'Espace client',
        },
        listing: {
            pageTitle: 'Tags',
        },
        edit: {
            pageTitle: 'Tags',
            general: {
                pageTitle: 'Configuration',
                subMenuLabel: 'Configuration',
            },
            meetings: {
                pageTitle: 'Événements',
                subMenuLabel: 'Événements',
                listing: {
                    helper: 'Sélectionnez les événements auxquels vous souhaitez associer ce tag.',
                    noResults: 'Pas de résultats',
                },
            },
        },
    },
    smtpSetting: {
        entity: {
            label: 'Nom / Identifiant',
            provider: 'Type de configuration via :',
            providers: {
                smtp: 'Smtp',
                brevoApi: 'API Brevo',
            },
            username: "Nom d'utilisateur",
            password: 'Mot de passe',
            host: 'Serveur',
            port: 'Port',
            encryption: 'Encryptage',
            auth_mode: "Mode d'authentification",
            sender_address: "Adresse de l'expediteur",
            sender_name: "Nom de l'expediteur",
            reply_to: 'Adresse de réponse',
            api_key: 'Clé API',
            encryptionOptions: {
                tls: 'tls',
                ssl: 'ssl',
            },
            auth_modeOptions: {
                plain: 'plain',
                login: 'login',
                'cram-md5': 'cram-md5',
            },
        },
        edit: {
            pageTitle: 'Paramètres SMTP',
        },
    },
    brevoApiSetting: {
        edit: {
            pageTitle: 'Paramètres Brevo',
        },
    },
    smsSetting: {
        entity: {
            label: 'Label',
            host: 'Serveur',
            account_id: 'Identifiant du compte',
            password: 'Mot de passe',
            sender_name: "Nom de l'expéditeur (11 caractères)",
            api_key: "Clé d'Api",
            sender_id: "Identifiant de l'expéditeur",
            provider: 'Type de configuration via',
            providerOptions: {
                fastsms: 'Fast SMS',
                smsmode: 'SMS Mode',
            },
        },
        edit: {
            pageTitle: 'Paramètres SMS',
        },
    },
    user: {
        entity: {
            name: 'Nom',
            last_name: 'Nom',
            first_name: 'Prénom',
            groups: 'Équipe',
            function: 'Fonction',
            area: 'Espace client',
            areas: 'Espaces clients',
            enabled: 'Actif',
            api_token: 'Clé API',
            enabledFilterLabel: 'Statut',
            enabledOptions: {
                enabled: 'Actif',
                disabled: 'Inactif',
            },
            email: 'Email',
            level: 'Niveau',
            levelOptions: {
                admin: 'Super Administrateur',
                manager: 'Administrateur de compte',
                group_manager: "Responsable d'équipe",
                consultant: 'Conseiller',
            },
            accountType: 'Compte',
            accountTypeOptions: {
                native: 'Interne',
                microsoft: 'Microsoft',
                google: 'Google',
            },
            agencies: 'Agence',
            external_calendar_granted: 'Calendrier',
            external_calendar_grantedOptions: {
                accessible: 'Accessible',
                inaccessible: 'Non accessible',
            },
            phone_number: 'Numéro de téléphone',
            service: 'Service',
            password: 'Mot de passe',
            confPassword: 'Confirmation du mot de passe',
            external_id: 'Identifiant externe',
            last_login_at: 'Dernière connexion',
            calendar_unreachable: 'Calendrier',
        },
        listing: {
            pageTitle: 'Utilisateurs',
            noResults: 'Pas de résultats',
            orderLastLoginLabel: 'Trier par dernière connexion',
        },
        edit: {
            pageTitle: 'Utilisateurs',
            general: {
                subMenuLabel: 'Général',
                pageTitle: 'Configuration générale',
            },
            notifications: {
                subMenuLabel: 'Notifications',
                pageTitle: 'Notifications rendez-vous',
                notificationTitle: 'Autoriser les notifications',
                notificationHelper:
                    "Pour pouvoir recevoir des notifications, vous devez explicitement autoriser l'application à vous en envoyer. Veuillez cliquer ci dessous pour lancer la fenêtre de configuration",
                notificationButton: 'Ouvrir',
            },
            accessibility: {
                subMenuLabel: 'Accessibilité',
                pageTitle: 'Accessibilité',
                swiperHelper: {
                    roleLevels: 'Rôles définis par niveau',
                    manager: {
                        line1: 'A la visibilité de l’ensemble de l’activité',
                        line2: 'Peut créer des agences, des groupes, des utilisateurs, des événements, des pages de préqualification',
                        line3: "Peut définir des profils d'utilisateurs",
                        line4: 'Peut gérer les notifications conseillers',
                        line5: 'Peut gérer la politique des données / cookies global ou par groupe',
                    },
                    group_manager: {
                        line1: 'A la visibilité de l’ensemble de l’activité',
                        line2: 'Peut créer des agences, des groupes, des utilisateurs, des événements, des pages de préqualification',
                        line3: "Peut définir des profils d'utilisateurs : responsable ou conseiller",
                        line4: 'Peut suivre les rendez-vous de son groupe',
                    },
                    consultant: {
                        line1: 'A la visibilité de l’ensemble de l’activité',
                        line2: 'Peut suivre ses rendez-vous et les déclarer : les modifier, annuler, déclarer manqués ou terminés',
                    },
                },
            },
            agenda: {
                subMenuLabel: 'Agenda',
                pageTitle: 'Agenda',
                configWidget: {
                    start: {
                        title: "Configuration de l'agenda interne",
                        content:
                            'Le type de connexion Interne permet de lier votre agenda interne (iCloud, Exchange…) à celui d’Apenday. La configuration est rapide et se fait en deux étapes.',
                        linkConfig: "Configurer l'agenda",
                        linkUpdate: "Modifier l'agenda",
                        endStatus: 'Votre agenda à bien était configuré',
                    },
                    generateApenday: {
                        title: 'Générer l’agenda',
                        content:
                            'En générant l’agenda Apenday, vous disposerez d’un calendrier standard dans lequel vous pourrez inscire vos rendez-vous externes à l’application Apenday afin qu’ils soient pris en compte dans le processus de prise de rendez-vous.',
                        link: 'Générer l’agenda Apenday',
                        orCopyLink: 'ou copiez l’URL suivante :',
                    },
                    importApenday_1: {
                        title: 'Etape',
                        content:
                            "Pour intégrer les rendez-vous Apenday dans votre agenda, vous devez importer le calendrier des rendez-vous Apenday. Cet agenda comportera l'ensemble de vos rendez-vous Apenday planifiés.",
                        link: 'Importer les rendez-vous Apenday',
                        orCopyLink: 'ou copiez l’URL suivante :',
                    },
                    importApenday_2: {
                        title: 'Etape',
                        content:
                            'Afin que l’application Apenday puisse consulter vos disponibilités, vous devez ajouter à Apenday l’adresse de vos calendriers publics.',
                        helper: 'Exemple : http://monserveurexchange.com/joris.baldan(at)habitalys.org.ics',
                        itemName: 'Calendrier',
                        urlExemple: 'Exemple : http://monserveurexchange.com/joris.baldan(at)habitalys.org.ics',
                        placeholder: 'http://url.ics',
                    },
                    noPublicUrl: 'Je ne dispose pas d’une url publique pour mon agenda',
                    tutoVideo: 'Tutoriel',
                    completed: 'Votre agenda a bien été configuré',
                },
                swiperHelper: {
                    panel1: {
                        title: 'La connexion aux Agendas permet:',
                        line1: 'De vérifier la réelle disponibilité d’un collaborateur pour proposer uniquement les créneaux réellement disponibles.',
                        line2: "De déposer directement le rendez-vous d'un client dans l’agenda du collaborateur.",
                    },
                    panel2: {
                        title: '3 connecteurs Agenda sont proposés :',
                        word1: 'Microsoft',
                        line1: 'Liaison automatique avec Office 365',
                        word2: 'Google',
                        line2: 'Liaison automatique avec Google Agenda',
                        word3: 'Interne',
                        line3: '(iCloud, Exchange…)  / Le type de connexion Interne est utilisé pour lier les agendas qui manipulent des fichiers ICS.',
                    },
                },
            },
            advanced: {
                subMenuLabel: 'Avancé',
                zapier: {
                    pageTitleAdmin: 'API',
                    pageTitle: 'Clé API',
                    helper: `Cette clé vous permet de connecter Apenday à d'autres applications comme Zapier. Vous pouvez par exemple intégrer vos rendez-vous dans vos outils métier.`,
                    toastSuccess: 'Clé régénérée avec succès',
                },
            },
            availability: {
                subMenuLabel: 'Disponibilité',
                meetings: {
                    subMenuLabel: 'Événements',
                    pageTitle: 'Événements',
                    listing: {
                        helper: ` Liste des événements où l'utilisateur est affecté. Cette section vous permet de gérer l'affectation de cet utilisateur sur vos événements. Cette action impactera automatiquement chaque événement (Equipes > Liste)`,
                        noResults:
                            "Aucun événement n'est disponible. Vérifiez que l'utilisateur est bien rattaché à des équipes utilisées sur les événements.",
                    },
                },
                unavailability: {
                    subMenuLabel: 'Indisponibilités',
                    pageTitle: 'Indisponibilités',
                    noResults: "Cet utilisateur n'a pas d'indisponibilités",
                },
                defaultAvailability: {
                    subMenuLabel: 'Créneaux par défaut',
                    pageTitle: 'Créneaux par défaut',
                },
                defaultAvailabilityAlert: {
                    title: 'Créneaux par défaut',
                    content: 'Souhaitez vous définir les disponibilités par défaut ?',
                },
            },
        },
        import: {
            pageTitle: 'Importer des utilisateurs',
        },
    },
    userCategory: {
        entity: {
            area: 'Espace client',
            type: 'Type',
            typeOptions: {
                function: 'Fonction',
                service: 'Service',
            },
            name: 'Label',
        },
        listing: {
            pageTitle: 'Services et fonctions',
            noResults: 'Pas de résultats',
        },
        edit: {
            pageTitle: 'Services et fonctions',
        },
    },
    dynamicFields: {
        entity: {
            params: 'Paramètre',
            constraints: 'Contraintes',
            display_column: 'Colonne',
            label: 'Nom du champ',
            required: 'Obligatoire',
            type: 'Type de champs',
            typeOptions: {
                label: 'type de champ',
                checkbox: 'Case à cocher',
                checkboxes: 'Case à cocher (sélection multiple)',
                file: 'Fichier',
                mention: 'Mention',
                radios: 'Boutons radios',
                select: 'Liste déroulante',
                datasource: 'Liste déroulante avec source de données',
                text: 'Champ texte',
                textarea: 'Zone de texte libre',
                email: 'Adresse e-mail',
                phone: 'Champ téléphone',
                date: 'Champ date',
                section_separator: 'Nouvelle section',
                hidden: 'Champ caché pré-rempli',
            },
            options: 'Options',
            contains_personal_data: 'Contient des données personnelles',
            query_string_parameter: 'Pré-remplissage',
            query_string_parameterHelper: 'Saisissez ici le nom du paramètre d’URL (GET) à utiliser pour préremplir le champs.',
            locked_when_prefilled: "Non modifiable par l'utilisateur",
            helper_text: "Texte d'aide",
            helper_text_placeholder: 'Ex: Sans espaces',
            datasource: 'Source de données',
            allow_other_option: 'Option "Autre"',
            required_pre_fill: 'Exiger le pré-remplissage',
        },
        edit: {
            deleteWarning:
                'Attention ! Supprimer un champ entraîne également la suppression des données associées aux rendez-vous précédents.',
        },
    },
    availability: {
        entity: {
            day_of_the_week: 'Jour',
            date: 'Date',
            time_sequences: 'Horaires',
            type: 'Fréquence',
            typeOptions: {
                recurrent: 'Récurrente',
                recurrents: 'Récurrentes',
                punctual: 'Ponctuelle',
                punctuals: 'Ponctuelles',
            },
            consultants: 'Équipe',
            agency: 'Agence',
        },
        selectAgencyNone: 'Sans préférences',
        noAgencyAvailableMin1: 'Sélection agence impossible, sélectionnez au minimum une personne',
        noAgencyAvailable: "Sélection d'agence impossible, pas d'agence en commun",
        noRecurrentAvailability: 'Vous n’avez indiqué aucune disponibilité récurrente',
        noPunctualAvailability: 'Vous n’avez indiqué aucune disponibilité ponctuelle',
        validityPeriodLabel: 'Période de validité',
        filters: {
            dateRange: 'Période',
            consultants: 'Nom',
        },
        teamMembers: {
            listing: {
                noResults: 'Liste {value}',
                addAllUsers: 'Tout ajouter',
            },
        },
        import: {
            pageTitle: 'Importer des disponibilités',
        },
        availabilityMultipleCreateBatch: {
            pageTitle: 'Création des disponibilités',
            workInProgress: 'Veuillez patienter',
            messages: {
                punctual: {
                    success: '{value} - Succès',
                    error: '{value} - Échec',
                },
                recurrent: {
                    success: '{value} - Succès',
                    error: '{value} - Échec',
                },
            },
        },
        actions: {
            add: 'Rajouter une plage horaire',
        },
    },
    notification: {
        entity: {
            types: {
                meeting_confirmed: 'Confirmation',
                meeting_updated: 'Modification client',
                meeting_cancelled: 'Annulation client',
                meeting_cancelled_by_consultant: 'Annulation conseiller',
                meeting_reminder: 'Rappel',
                meeting_terminated: 'Terminé',
                meeting_missed: 'Manqué',
                meeting_delayed: 'Retard',
                meeting_pending_finalisation: 'RDV à qualifier',
            },
            channels: {
                email: 'Email',
                sms: 'SMS',
                push: 'Push',
            },
            email: {
                subject: "Sujet de l'email",
                content: 'Contenu',
            },
            sms: {
                content: 'Contenu',
            },
            push: {
                subject: 'Sujet de la notification push',
                content: 'Contenu',
            },
        },
        add_reminder: 'Ajouter un rappel',
        disabledPlaceholder: {
            meeting_confirmed: {
                email: '',
                sms: '',
                push: '',
            },
            meeting_updated: {
                email: '',
                sms: '',
                push: '',
            },
            meeting_cancelled: {
                email: '',
                sms: '',
                push: '',
            },
            meeting_cancelled_by_consultant: {
                email: '',
                sms: '',
                push: '',
            },
            meeting_reminder: {
                email: 'Bonne pratique : rappelez à votre client votre rendez-vous et ses modalités. Vous éviterez ainsi les rendez-vous manqués.',
                sms: 'En activant la notification SMS vous divisez par 4 le nombre de rendez-vous manqués.',
                push: '',
            },
            meeting_terminated: {
                email: "Suite à votre rendez-vous, vérifiez que votre interlocuteur est satisfait de votre échange en lui transmettant une enquête de satisfaction. Pour cela intégrez le lien de l'enquête via la variable {'{scheduled_meeting:satisfaction_survey_url}'}.",
                sms: "Suite à votre rendez-vous, vérifiez que votre interlocuteur est satisfait de votre échange en lui transmettant une enquête de satisfaction. Pour cela intégrez le lien de l'enquête via la variable {'{scheduled_meeting:satisfaction_survey_url}'}.",
                push: '',
            },
            meeting_missed: {
                email: "Notifiez à votre correspondant que vous l'attendiez lors de votre rendez-vous et renouer le contact en l'invitant à vous recontacter.",
                sms: '',
                push: '',
            },
            notify_user: {
                email: '',
                sms: '',
            },
            notify_consultant: {
                email: '',
                sms: '',
            },
            meeting_delayed: {
                email: 'Notifiez votre correspondant de votre retard.',
                sms: 'Notifiez votre correspondant de votre retard.',
            },
            meeting_pending_finalisation: {
                email: '',
                sms: '',
                push: '',
            },
        },
        center: {
            panelTitle: 'Notifications non lues',
            see: 'Voir',
            seeAll: 'Voir tout',
            empty: 'Pas de nouvelles notifications',
            tooltip: 'Notifications',
            refresh: 'Rafraichir',
            marAllAsRead: 'Tout marquer comme lu',
        },
        modal: {
            title: 'Soyez Notifié',
            body: 'Si vous souhaitez recevoir des notifications sur cet appareil concernant les rendez-vous, vous devez autoriser les notifications.',
            body2: 'Gestion des notifications via votre compte utilisateur',
            authorize: 'Autoriser les notifications',
        },
        meeting_pending_finalisation: {
            frequency: "Fréquence d'envoi",
            frequency_description:
                "L'alerte est envoyée du lundi au vendredi à <strong>7h (heure Europe/Berlin)</strong> aux conseillers ayant des rendez-vous à qualifier.",
            title: 'Objectif',
            frequency_description_2: 'Informer les conseillers des rendez-vous à qualifier pour un meilleur suivi.',
            activation: 'Activation',
            activation_description:
                'Chaque utilisateur doit activer cette notification dans ses préférences pour recevoir un e-mail quotidien (hors week-end).',
            functionality: 'Fonctionnement',
            functionality_description:
                "L'e-mail contient un lien direct vers la plateforme. Une fois qualifiés, les rendez-vous disparaissent des alertes.",
        },
    },
    supportThread: {
        entity: {
            id: 'ID',
            area: 'Espace client',
            author: 'Demandeur',
            created_by: 'Auteur',
            status: 'Statut',
            title: 'Sujet',
            content: 'Description',
            message_number: 'Messages',
            severity: 'Priorité',
            updated_at: 'Mis à jour',
            statusType: {
                closed: 'Fermé',
                in_progress: 'En cours',
                opened: 'Ouvert',
                rejected: 'Rejeté',
                resolved: 'Résolu',
                waiting: 'En attente',
            },
            assignee: 'Attribué à',
            type: 'Type',
            typeOptions: {
                incident: 'Incident',
                support: 'Assistance fonctionnelle',
                feature_request: "Demande d'évolution",
            },
            severityOptions: {
                0: 'Bas',
                10: 'Normal',
                20: 'Haut',
                30: 'Critique',
            },
            solution: 'Solution',
            spent_time: 'Temps passé',
        },
        listing: {
            pageTitle: 'Bienvenue {name}',
            noResults: 'Pas de résultats',
            header: {
                create: 'Créer',
                opened: 'Ouverts',
                inProgress: 'En cours',
                waiting: 'En attente',
                resolved: 'Résolus',
                openTicket: 'Créer un ticket',
                contactTeam: 'Contacter notre équipe',
                watchVideos: 'Consulter les tutoriels',
                phoneNumber: '0970 737 365',
                tutosCta: 'Tutoriels',
            },
            area_solution: 'Esp. Client / Solution',
        },
        edit: {
            pageTitle: 'Ticket #{value}',
            pageTitleCreate: 'Nouveau ticket',
            messagesTitle: 'Messages',
            addMessageBody: 'Posez une question ou laissez un message',
        },
        filters: {
            dateRange: 'Période',
        },
        historyEntry: {
            created: 'Créé par <strong>{name}</strong><br> le {date} à {time}',
            severity_changed: 'Priorité modifiée par <strong>{name}</strong><br> le {date} à {time}<br><strong>{new}</strong>',
            status_changed: 'Statut modifié par <strong>{name}</strong><br> le {date} à {time}<br><strong>{new}</strong>',
            assignment_changed: 'Attribué à <strong>{name}</strong><br> le {date} à {time}',
        },
    },
    page: {
        entity: {
            title: 'Titre',
            content: 'Contenu',
            access_level: "Niveau d'accès",
            show: 'Afficher',
        },
        listing: {
            pageTitle: 'Tutoriels',
            noResults: 'Pas de pages actuellement',
        },
        edit: {
            pageTitle: 'Page',
        },
    },
    ftpExport: {
        entity: {
            enabled: 'Actif',
            label: 'Nom',
            ftp_host: 'Adresse host',
            ftp_user: 'Login',
            ftp_password: 'Mot de passe',
            recurrence_rule: 'Périodicité',
            ftp_port: 'Port',
            statuses: 'Statuts',
            ftp_path: "Chemin d'accès",
            file_name_pattern: 'Format du fichier généré',
            export_all_columns: 'Exporter toutes les colonnes disponibles',
            exported_columns: 'Colonnes exportées',
            column_separator: 'Séparateur de colonnes',
            multiple_values_separator: 'Séparateur de valeurs multiples',
            export_type: "Type d'export",
            typeOptions: {
                full: 'Complet',
                diff: 'Séquentiel',
            },
        },
        listing: {
            pageTitle: 'Exports FTP',
            noResults: "Pas d'exports FTP pour le moment",
        },
        edit: {
            pageTitle: 'Export FTP',
            labelHelper: 'Entrez un nom à votre tâche',
            configSrv: 'Configuration serveur',
            ftpHostPlaceholder: 'exemple.com',
            exportAllColumnsHelper: "Si aucune colonne n'est sélectionnée, elles seront toutes exportées par défaut",
        },
    },
    occupancy: {
        entity: {
            next_available: 'Prochain créneau disponible',
            scheduled_count: 'Planifiés',
            remaining_count: 'Disponibles',
            occupancy_rate: 'Occupation',
            total: 'Total',
            other: 'Autres créneaux',
        },
        sidePanel: {
            pageTitle: '% Occupation',
            inProgress: 'En cours',
            period: 'Période J+7',
            detail: 'Détails',
        },
    },
    invite: {
        entity: {
            email: 'Adresse email',
            consultant: 'Consultant',
            consultantHelper: 'Laissez vide pour permettre le choix à votre correspondant',
            consultantOnlyOne: 'Un seul intervenant disponible',
            agency: 'Agence',
            agencyHelper: 'Laissez vide pour permettre le choix à votre correspondant',
            proposed_date_times: 'Rendez-vous choisis',
            field_values: 'Informations client (optionnel)',
            subject: "Sujet de l'email",
            subjectPlaceholder: 'Vous avez été invité sur un rendez-vous',
            message: "Corps du message de l'email",
            messagePlaceholder: 'On vous propose les créneaux ci-dessous',
        },
        alerts: {
            sendSuccess: "L'invitation a bien été envoyée par email",
        },
    },
    unavailability: {
        entity: {
            start: 'Début',
            end: 'Fin',
        },
        listing: {
            helper: 'Vous pouvez définir ici vos indisponibilités',
        },
        edit: {
            pageTitle: 'Définir une indisponibilité',
        },
    },
    interaction: {
        entity: {
            user_identity: 'Nom',
            user_email: 'Email',
            status: 'Statut',
            channel: 'Canal',
            created_at: 'Date',
            started_at: 'Date',
            duration: 'Durée',
            area: 'Espace client',
            dynamic_field_values: 'Informations client',
            view: 'Voir',
            waiting: 'Attente',
            widget: 'Widget',
        },
        pending: {
            pageTitle: 'En attente',
            emptyList: "Pas d'intéraction en attente pour le moment",
        },
        inProgress: {
            pageTitle: 'En cours',
            emptyList: "Pas d'intéraction en cours pour le moment",
        },
        closed: {
            pageTitle: 'Historique',
            emptyList: "Pas d'intéraction archivé pour le moment",
        },
        dashboard: {
            pageTitle: 'Live',
            closedListCount: 'Terminés ({count})',
            pendingListCount: "File d'attente ({count})",
        },
        card: {
            actions: {
                chat: 'Discuter',
            },
            waitingLabel: "d'attente",
        },
        messagingView: {
            closedConversationLabel: 'Conversation terminée',
            lastMessage: 'Dernier message {value}',
            noActive: 'Veuillez sélectionner une interaction',
            serviceMessage: {
                consultant_joined: 'Un conseiller a rejoint la conversation',
                conversation_closed: 'Conversation terminée',
            },
            ownerLabel: 'Moi',
            end_message_placeholder: 'La conversation est terminée, merci et bonne journée.',
            newMessage: 'Nouveau message',
            connectionState: {
                unknown: 'Inconnu',
                disconnecting: 'Déconnexion',
                disconnected: 'Déconnecté',
                connecting: 'Connexion',
                connected: 'Connecté',
                denied: 'Connexion Refusée',
                error: 'Erreur de connection',
            },
        },
        videoView: {
            leave: 'Quitter',
            enter: 'Rejoindre',
            closed: 'Salle fermée',
            empty: "Personne n'est actuellement connecté",
            end_message_placeholder: 'La conversation est terminée, merci et bonne journée.',
            record_impossible: "Impossible d'activer ou désactiver le son ou la caméra pendant un enregistrement",
            impossible_during_screen_share: "Impossible d'activer la caméra pendant le partage d'écran",
            record_start_admin: "L'enregistrement a démarré, informez votre interlocuteur qu'il est enregistré.",
            record_start_assist: "L'enregistrement a démarré. En participant à cette réunion, vous acceptez d'être enregistré.",
            record_start_live: "L'enregistrement a démarré. En participant à cette réunion, vous acceptez d'être enregistré.",
            record_stop: 'Enregistrement terminé',
            screenshotNotification: 'Écran sauvegardé',
            tooltips: {
                screenshot: "Capture d'écran",
                record_on: "Démarrer l'enregistrement",
                record_off: "Arrêter l'enregistrement",
                fullscreen_on: 'Agrandir',
                fullscreen_off: 'Réduire',
                camera_on: 'Activer votre caméra',
                camera_off: 'Désactiver votre caméra',
                audio_on: 'Activer votre micro',
                audio_off: 'Désactiver votre micro',
                params: 'Paramètres',
                screen_share_on: "Partage d'écran",
                screen_share_off: "Arrêter le partage d'écran",
                pointer_on: 'Activer le pointeur',
                pointer_off: 'Désactiver le pointeur',
            },
            settings: {
                camera: 'Caméra',
                mic: 'Micro',
                speaker: 'Haut-parleurs',
            },
        },
        clickToCallView: {
            end_message_placeholder: 'Appel en cours ...',
        },
        listing: {
            created_at: 'Début de solicitation le {date} à {time}',
            started_at: 'Début de conversation le {date} à {time}',
            closed_at: 'Fermé le {date} à {time}',
            ended_at: 'Fin de conversation le {date} à {time}',
        },
    },
    session: {
        entity: {
            user_identity: 'Identifiant Client',
            user_identityHelper: "Merci d'indiquer une référence client et/ou un Nom et un Prénom",
            user_email: 'Email',
            user_phone_number: 'Numéro de téléphone',
            status: 'Statut',
            channel: 'Canal',
            created_at: 'Date',
            started_at: 'Date',
            duration: 'Durée',
            area: 'Espace client',
        },
        edit: {
            pageTitle: 'Envoyez un lien visio',
            link: 'Copier le lien',
        },
        action: {
            resend: 'Renvoyer',
        },
        dashboard: {
            pageTitle: 'Assist',
        },
        inProgress: {
            pageTitle: 'En cours',
            emptyList: 'Pas de session en cours pour le moment',
        },
        closed: {
            pageTitle: 'Historique',
            emptyList: 'Pas de session archivé pour le moment',
        },
        messagingView: {
            noActive: 'Veuillez sélectionner une session',
        },
        modal: {
            share: {
                title: 'Partage de médias',
                form: {
                    email: 'Adresses emails',
                    email_subject: "Sujet de l'email",
                    email_content: "Corps de l'email",
                },
                buttonLabel: 'Partager par email',
                sendSuccess: 'Mail envoyé avec succès',
            },
        },
        listing: {
            created_at: "Création de l'invitation le {date} à {time}",
            started_at: 'Début de conversation le {date} à {time}',
            ended_at: 'Fin de conversation le {date} à {time}',
        },
    },
    widget: {
        entity: {
            name: 'Nom',
            enabled: 'Actif',
            enabled_channels: 'Canaux',
            groups: 'Équipes',
            enabledChannelsOptions: {
                messaging: 'Chat',
                video: 'Visioconférence',
                click_to_call: 'Rappel immédiat',
            },
            displayModeOptions: {
                compact: 'Icones seulements',
                advanced: 'Icones et labels',
                rich: 'Complet',
            },
            startingDisplayModeOptions: {
                button: 'Bouton seulement',
                collapsed: 'Bandeau fermé',
                expanded: 'Bandeau ouvert',
            },
            content_display_mode: "Mode d'affichage",
            introduction_text: "Texte d'introduction",
            fallback_description: 'Description bouton rendez-vous',
            channel_messaging_description: 'Description bouton chat',
            channel_video_description: 'Description bouton visio',
            channel_click_to_call_description: 'Description bouton "Être rappelé"',
            title: 'Titre dans le bandeau',
            starting_display_mode: 'Affichage au démarrage',
            titlePlaceholder: 'Vous avez une question ?',
            introductionPlaceholder: 'Bonjour, nous sommes là pour vous aider ;)',
            fallbackDescriptionPlaceholder: 'Prenez rendez-vous en quelques secondes quand vous le souhaitez.',
            channelVideoDescription: 'Obtenez des réponses immédiates à vos questions grâce à la visio.',
            channelMessagingDescription: 'Discutez avec un conseiller en ligne, partagez des documents instantanément',
            channelClickToCallDescription: 'Obtenez des réponses immédiates à vos questions grâce à un appel vocal.',
            users: 'Utilisateurs',
            usersPlaceholder: 'Associez des utilisateurs',
            fallback_policy: "Action lorsqu'aucun utilisateur n'est disponible",
            fallback_policyOption: {
                hidden: 'Masquer le widget',
                message: 'Afficher un message',
                hub: 'Proposer une page de préqualification',
                meeting: 'Proposer un événement',
            },
            fallback_messages: {
                no_consultant_available: "Message de repli lorsqu'aucun conseiller n'est disponible",
                no_consultant_availablePlaceholder: 'Tous nos conseillers sont occupés, veuillez patienter',
                widget_unavailable: "Message de repli en dehors des horaires d'ouvertures",
                widget_unavailablePlaceholder: 'Nous sommes fermés, veuillez nous excuser',
            },
            fallback_messagePlaceholder: 'Actuellement tout nos conseillers sont occupés, veuillez nous excuser.',
            fallback_hub: 'Page de préqualification',
            fallback_meeting: 'Événement',
            welcome_message_conversation: 'Message de bienvenue dans une conversation chat',
            waiting_message_conversation: "Message d'attente pour le chat",
            waiting_message_video: "Message d'attente pour la video",
            welcome_message_conversationPlaceholder: 'Bienvenue dans la conversation',
            waiting_message_conversationPlaceholder: 'Un conseiller va prendre votre conversation, veuillez patienter.',
            waiting_message_videoPlaceholder: 'Un conseiller va prendre votre appel, veuillez patienter.',
            end_message_video: 'Message de fin de conversation video',
            end_message_videoPlaceholder: 'La conversation est terminée, merci et bonne journée.',
            end_message_conversation: 'Message de fin de conversation chat',
            end_message_conversationPlaceholder: 'La conversation est terminée, merci et bonne journée.',
            show_fallback_even_when_widget_is_available: "Permettre la prise de rendez-vous même lorsqu'un conseiller est en ligne.",
            max_simultaneous_interactions: 'Nombre maximum de conversations chat en simultané',
            max_simultaneous_interactions_placeholder: 'Laissez vide pour ne pas limiter',
            click_to_call_confirmation_text: 'Texte de confirmation',
            click_to_call_confirmation_text_placeholder: 'Texte de confirmation',
            click_to_call_introduction_text: "Texte d'introduction",
            click_to_call_introduction_text_placeholder: 'Veuillez saisir votre numéro sur lequel vous souhaitez être rappelé',
            click_to_call_target_number: "Numéro de téléphone d'aboutement",
            click_to_call_waiting_message: "Message vocal d'attente",
            click_to_call_waiting_message_placeholder:
                'Bonjour ! Merci de patienter un instant, nous allons vous mettre en relation avec un conseiller',
            click_to_call_allow_simultaneous_calls: 'Autoriser les appels simultanés',
            chatbot_enabled: 'Utiliser le bot Apenday',
            chatbot_enabled_helper:
                'Le bot Apenday est un assistant virtuel qui peut répondre aux questions basés sur la documentation fournie dans l\'onglet "Bot"',
            chatbot_prompt: 'Prompt',
            chatbot_nickname: "Nom de l'assistant affiché",
            ai_assistant: "Choix du profil assistant IA",
            ai_assistant_helper: "Ces profils sont contribués dans votre espace client",
        },
        listing: {
            pageTitle: 'Widgets',
        },
        edit: {
            pageTitle: 'Widgets',
            configuration: {
                subMenuLabel: 'Configuration',
                general: {
                    pageTitle: 'Configuration générale',
                },
                wording: {
                    pageTitle: 'Vocabulaire',
                },
            },
            team: {
                subMenuLabel: 'Équipe',
                list: {
                    pageTitle: 'Équipe',
                },
                availability: {
                    pageTitle: 'Gestion des disponibilités',
                    subMenuLabel: 'Disponibilités',
                },
            },
            appearance: {
                subMenuLabel: 'Apparence',
                appearanceContent: {
                    pageTitle: 'Apparence',
                    startingPreview: 'Aperçu au démarrage',
                    openPreview: 'Une fois le widget ouvert',
                },
            },
            bot: {
                subMenuLabel: 'Bot',
                config: {
                    pageTitle: 'Configuration',
                },
                assets: {
                    pageTitle: 'Documents',
                },
            },
            dynamicFields: {
                subMenuLabel: 'Formulaire',
            },
            satisfaction: {
                subMenuLabel: 'Satisfaction',
                satisfactionSurveyQuestionPlaceholder: 'Êtes-vous satisfait de votre échange ?',
            },
        },
        publish: {
            pageTitle: 'Intégration',
            informations: {
                subMenuLabel: 'Informations',
                informations: {
                    pageTitle: 'Testez votre widget',
                    link: "Testez votre widget en cliquant sur l'icone ci-dessous",
                },
            },
            integration: {
                subMenuLabel: 'Intégration',
                integration: {
                    pageTitle: 'Intégration',
                    helper: 'Code à insérer dans le code source de votre page html',
                },
            },
        },
        joinBtnHelpers: {
            meeting: 'Prenez rendez-vous en quelques secondes quand vous le souhaitez',
            messaging: 'Discutez avec un conseiller en ligne, partagez des documents instantanément',
            video: 'Obtenez des réponses immédiates à vos questions grâce à la visio',
            audio: 'Obtenez des réponses immédiates à vos questions grâce à un appel vocal',
            click_to_call: 'Obtenez des réponses immédiates à vos questions grâce à un appel vocal',
        },
        joinBtnLabels: {
            meeting: 'Rendez-vous',
            messaging: 'Lancer un chat',
            video: 'Lancer une visio',
            audio: 'Lancer un appel',
            click_to_call: 'Rappel immédiat',
        },
        header: {
            chat: 'LIVE CHAT',
            video: 'APPEL VISIO',
            click_to_call: 'RAPPEL IMMÉDIAT',
        },
        summaryBody: 'Bonjour, nous sommes là pour vous aider ;)',
        summaryForm: 'Pour commencer à vous aider merci de compléter ces informations',
        live_privacy_policy:
            '<p>J\'accepte que mes données personnelles soient traitées par <strong class="tw-font-bold">{0}</strong>.</p><p><a href="{1}" class="tw-underline" target="_blank">Voir la politique de données</a></p>',
        form: {
            fields: {
                ask: 'Posez votre question',
                phone_number: 'Insérez votre numéro de téléphone',
            },
        },
        satisfaction: {
            comment_placeholder: 'Laissez un commentaire',
            error_score: 'Vous devez donner une note',
        },
    },
    widgetAvailability: {
        entity: {
            days_of_the_week: 'Jour(s)',
            time: 'Horaire',
            validity: 'Période',
        },
        edit: {
            pageTitle: 'Disponibilité',
            days_of_the_week: "Définir les jours d'activités",
            time: 'Plage horaire',
            timePlaceholder: 'Définissez la tranche horaire',
            validity: 'Définir une période',
            agency: 'Agence',
            agencyPlaceholder: 'Sélectionner une agence',
        },
    },
    statusChange: {
        entity: {
            status: 'Status',
            enabled_channels: 'Canaux actifs',
            statusOptions: {
                online: 'En ligne',
                offline: 'Hors ligne',
                busy: 'Occupé',
                in_call: 'En appel',
                paused: 'En pause',
            },
        },
    },
    closingPeriod: {
        edit: {
            pageTitle: 'Période de fermeture',
        },
        listing: {
            helper: 'Vous pouvez définir ici les périodes de fermetures de votre entreprise.',
        },
    },
    dataSource: {
        entity: {
            area: 'Espace client',
            label: 'Nom',
            datasource_file: 'Fichier CSV',
        },
        listing: {
            pageTitle: 'Source de données',
        },
        edit: {
            pageTitle: 'Source de données',
        },
    },
    aiAssistant: {
        entity: {
            id: 'Identifiant OpenAI',
            idHelper: 'Identifiant unique de chez OpenAI',
            name: 'Nom du profil assistant',
            nameHelper: 'Nom du profil assistant',
            initialPrompt: 'Prompt',
            initialPromptHelper: 'Prompt initial pour l\'assistant',
        },
        listing: {
            pageTitle: 'Profils assistant IA',
            noResults: 'Pas de profil assistant IA',
        },
    },
    // end Entity
    debug: {
        modalTitle: 'Informations',
        created: 'Créé',
        updated: 'Modifié',
        deleted: 'Supprimé',
        enabled: 'Activé',
        disabled: 'Désactivé',
        ipAddress: 'Adresse IP',
        changedProperties: 'Modifications',
        by_user: 'par {user}',
        noInformation: 'Historique non disponible.',
        identifier: 'ID',
    },
    components: {
        sequencePicker: {
            noSequenceSoon: 'Pas de créneaux disponibles sur les 6 prochains mois',
            loadingSoon: 'Recherche du premier rendez-vous disponible',
            error: "Il y a eu une erreur avec le serveur, n'hésitez pas à faire remonter l'information via notre outil d'assistance intégré",
            remaining_capacity: 'Aucune place restante | 1 place restante | { count } places restantes',
            or_time_choice: 'ou précisez un horaire manuellement',
            no_sequences: 'Pas de créneaux sur la date sélectionnée mais vous pouvez définir un horaire manuellement',
            sequences_available: 'Pas de créneau disponible | Un créneau disponible | {count} créneaux disponibles',
            check_availability: 'Vérifier la disponibilité',
            available: 'Disponible',
            not_available: 'Non disponible',
            no_active_month_sequences: 'Pas de créneau disponible sur cette période',
        },
    },
    dashboard: {
        title: 'Bienvenue sur apenday, {value}',
        quickAccess: {
            title: 'Accès rapide',
            area: 'Créer un espace client',
            meeting: 'Créer un événement',
            hub: 'Créer une page de préqualification',
            tutorial: 'Créer un tutoriel',
            news: 'Créer une nouveauté',
        },
        activity: {
            title: 'Les statistiques des activités',
        },
        actions: 'Les actions',
        lastSupportThread: 'Derniers tickets',
        showAllSupportThread: 'Voir tous les tickets',
        showAllStats: "Voir toute l'activité",
        // Hub
        showAllHubs: 'Voir toutes les pages de préqualification',
        hubs: 'Les préqualifications',
        createHub: 'Créer une nouvelle page de préqualification',
        lastsHubs: 'Les dernières pages de préqualification',
        // Meeting
        showAllMeetings: 'Voir tous les événements',
        meetings: 'Les événements',
        createMeeting: 'Créer un nouvel événement',
        lastsActiveMeetings: 'Les événements en cours',
        lastsMeetings: 'Les derniers événements',
        // ScheduledMeeting
        showAllForthcomingScheduledMeetings: 'Voir tous les rendez-vous planifiés',
        showAllPastScheduledMeetings: 'Voir tous les rendez-vous en cours',
        pastScheduledMeetings: 'Les rendez-vous en cours',
        nextScheduledMeetings: 'Les prochains rendez-vous planifiés',
        noItemsCreated: 'Aucun élément créé',
    },
    reporting: {
        pageTitle: 'Les statistiques',
        dashboard: {
            areaCount: 'Espace client actif | Espace client actif | Espaces clients actifs',
            allUsers: 'Compte utilisateur actif | Compte utilisateur actif | Comptes utilisateurs actifs',
        },
        summary: {
            usersCount: "Nombre d'utilisateurs",
            agenciesCount: "Nombre d'agences",
            activeMeetingsCount: 'Événements actifs',
        },
        meeting: {
            subMenuLabel: 'Événement / Rendez-vous',
            keyNumbers: 'Chiffres clés',
            statusRepartition: 'Rendez-vous',
            scheduledMeetingStatus: {
                scheduled: 'Rendez-vous planifiés',
                terminated: 'Rendez-vous terminés',
                missed: 'Rendez-vous manqués',
            },
            modeRepartition: 'Événements',
            modes: {
                group: 'Groupe',
                one_to_one: 'Individuel',
                team: 'Collectif',
                total: 'Total',
            },
            scheduledMeetingEvolution: 'Evolution du nombre de rendez-vous',
        },
        live: {
            keyNumbers: 'Activités',
        },
        consultant: {
            subMenuLabel: 'Conseiller',
            totalScheduledMeeting: 'Total',
            listLabel: 'Nom',
        },
        scheduler: {
            subMenuLabel: 'Planificateur',
            listLabel: 'Nom',
            invitedCount: 'Invitations',
            scheduledCount: 'Rendez-vous',
            schedulerHelper:
                'Le planificateur de rendez-vous est la personne qui prend l’initiative d’organiser un rendez-vous en partageant des créneaux de disponibilité ou en positionnant directement un rendez-vous dans l’agenda d’un interlocuteur, tout en prenant en compte les rendez-vous planifiés, en cours et terminés.',
        },
        agency: {
            subMenuLabel: 'Agence',
            listLabel: 'Nom',
        },
        satisfaction: {
            subMenuLabel: 'Satisfaction',
            satisfactionEvolution: 'Evolution de la satisfaction (moyenne)',
            top: 'Top 3 des avis',
            flop: 'Flop 3 des avis',
            onTotal: 'sur {count} avis',
            noComment: 'Pas de commentaire',
            averageOnTotal: 'moyenne sur {count} avis',
        },
        tags: {
            subMenuLabel: 'Suivi des tags',
        },
        consumption: {
            subMenuLabel: 'Consommation',
        },
        interaction: {
            pageTitle: 'Interactions',
            subMenuLabel: 'Interactions',
            number_evolution: "Evolution du nombre d'interactions",
            duration_average: 'Durée moyenne des interactions',
            pending_time_average: "Durée moyenne d'attente",
            total_number: 'Interactions',
            average_number_by_user: 'interactions par conseiller',
            average_number_by_widget: 'interactions par widget',
            consultant: {
                listLabel: 'Nom',
                averageDuration: 'Durée moyenne',
                totalDuration: 'Durée cumulée',
                averagePendingTime: "Durée moyenne d'attente",
                totalInteractionNumber: 'Nombre de sessions',
            },
        },
        session: {
            pageTitle: 'Sessions',
            subMenuLabel: 'Sessions',
            number_evolution: 'Evolution du nombre de sessions',
            duration_average: 'Durée moyenne des sessions',
            pending_time_average: "Durée moyenne d'attente",
            total_number: 'Sessions',
            average_number_by_user: 'sessions par conseiller',
            average_number_by_widget: 'sessions par widget',
            consultant: {
                listLabel: 'Nom',
                averageDuration: 'Durée moyenne',
                totalDuration: 'Durée cumulée',
                totalInteractionNumber: 'Nombre de sessions',
            },
        },
        // maybe move
        users: {
            title: 'Activités',
            types: {
                admin: 'Super Administrateur actif | Super Administrateur actif | Super Administrateurs actifs',
                manager: 'Administrateur de compte actif | Administrateur de compte actif | Administrateurs de compte actifs',
                group_manager: "Responsable d'équipe actif | Responsable d'équipe actif | Responsables d'équipe actifs",
                consultant: '{label} actif | {label} actif | {label} actifs',
            },
        },
        components: {
            listValue: {
                tooltip: '{value}% par rapport à la période du {startTime} au {endTime}',
                tooltipNoTime: '{value}%',
            },
        },
    },
    form: {
        select: {
            fieldMoreOne: '{count} {entity} sélectionnées',
            items: 'choix',
            noResults: 'Pas de résultats',
            noSelect: 'Aucun',
            searchPlaceholder: 'Rechercher',
            noMatchWithOption: 'Donnée érronée',
        },
        inputDuration: {
            intervalOptions: {
                second: 'Seconde(s)',
                seconds: 'Seconde(s)',
                minute: 'Minute(s)',
                minutes: 'Minute(s)',
                min: 'Minute(s)',
                hour: 'Heure(s)',
                hours: 'Heure(s)',
                day: 'Jour(s)',
                days: 'Jour(s)',
                week: 'Semaine(s)',
                weeks: 'Semaine(s)',
                month: 'Moi(s)',
                months: 'Moi(s)',
                year: 'Année(s)',
                years: 'Année(s)',
            },
        },
        cron: {
            choiceOptions: {
                everyDay: 'Tous les jours à',
                interval: 'Tou(te)s les',
                cron: 'Syntaxe crontab',
            },
            error: 'Syntaxe crontab invalide',
        },
        ckeditor: {
            wordCount: {
                word: 'Longueur du message: {0}/{1} caractères',
                sms: 'Nombre de SMS: {0}',
            },
        },
    },
    import: {
        column_separator: 'Séparateur de colonnes',
        multiple_values_separator: 'Séparateur de valeurs multiples',
        file: 'Fichier source (csv)',
        multipleToggle: "Permettre l'import pour plusieurs événements",
        purgeDateRange: 'Période de suppression',
        purgeDateRangeHelper: 'Vous pouvez définir une période où vous souhaitez supprimer toutes les disponibilités déjà contribuées',
        purgeAllConsultants: 'Supprimer les disponibilités pour tous les utilisateurs.',
        purgeAllConsultantsHelper:
            "Si cette option n'est pas cochée, seule les disponibilités des conseillers présents dans le fichier seront supprimées.",
        noPurge: 'Pas de période définie',
        importInProgress: 'Import en cours',
        importComplete: 'Import terminé',
        preparation: 'Phase de préparation',
        lineNumber: 'Ligne {lineNumber} - ',
        lineStatus: {
            success: 'OK',
            warning: 'Partielle',
            error: 'Invalide',
        },
        lineErrorsCount: '({count} messages)',
        endMessage: 'Import terminé. {imported} créé, {updated} mis à jour, {ignored} ignorés sur un total de {total}',
        actions: {
            startImport: "Lancer l'import",
            downloadExemple: "Télécharger un fichier d'exemple",
            addFile: 'Aucun fichier choisi',
            removeFile: 'Supprimer',
            closeImport: 'Fermer',
        },
    },
    auth: {
        loginPage: {
            title: 'Connexion',
            login: {
                placeholder: 'Email',
            },
            password: {
                label: 'Mot de passe',
                placeholder: 'Mot de passe',
            },
            submit: 'Je me connecte',
            googleLogin: 'Se connecter avec Google',
            microsoftLogin: 'Se connecter avec Microsoft',
            regularLogin: 'Ou vous avez un compte',
            remember: 'Rester connecté',
            forgotPassword: 'Mot de passe oublié ?',
            notYetAccount: 'Pas encore de compte ?',
            askForDemo: 'Demander une démo',
        },
        resetPassword: {
            title: 'Réinitialiser votre mot de passe',
            send: 'Réinitialiser',
            resetSent: 'Le mail a bien été envoyé',
            backConnexion: 'Retour connexion',
            again: 'Réessayer',
        },
        changePassword: {
            title: 'Nouveau mot de passe',
            send: 'Confirmer',
            confirmationMsg: 'Le mot de passe a été changé avec succès',
        },
        loadingMessage: 'Vérification de l`utilisateur',
        logoutInProgress: 'Déconnexion en cours',
    },
    loading: 'Chargement',
    actions: {
        buttons: {
            cancelScheduledMeeting: {
                cancel: 'Annuler ce rendez-vous',
                cancelled: 'Annulé le {value}',
                cancelled_by_consultant: 'Annulé par le conseillé le {value}',
            },
        },
        add: 'Ajouter',
        addAll: 'Ajouter tout',
        apply: 'Appliquer',
        cancel: 'Annuler',
        clipboard: 'Copier dans le presse-papier',
        close: 'Fermer',
        create: 'Créer',
        createAndNext: 'Créer et continuer',
        delete: 'Supprimer',
        disable: 'Désactiver',
        duplicate: 'Dupliquer',
        edit: 'Modifier',
        enable: 'Activer',
        filter: 'Filtrer',
        import: 'Importer',
        next: 'Continuer',
        reassign: 'Réaffecter',
        removeAll: 'Enlever tout',
        reorder: 'Réordonner',
        reset: 'Réinitialiser',
        restart: 'Recommencer',
        saveAndNext: 'Enregistrer et continuer',
        see: 'Voir',
        select: 'Sélectionner',
        selectAll: 'Sélectionner tout',
        send: 'Envoyer',
        stop: 'Stop',
        terminate: 'Terminer',
        update: 'Mettre à jour',
        validate: 'Valider',
    },
    validation: {
        required: 'Ce champ est requis',
        email: 'E-mail valide requis',
        min: 'Minimum {count} requis | Minimum {count} requis | Minimum {count} requis',
        stringMax: 'Maximum {count} caractère requis | Maximum {count} caractère requis | Maximum {count} caractères requis',
        stringLength: '{count} caractère attendu | {count} caractère attendu | {count} caractères attendu',
        phoneNumber: 'Numéro de téléphone non valide',
        oneOf: "Une de ces valeurs doit être présente : '{values}'",
        url: 'Adresse invalide',
        meetingDuplicateType: "Il ne peut y avoir qu'un seul événement du même type dans cette combinaison",
        hubChoiceMin2Childs: 'Il doit y avoir au moins 2 sous-motifs',
        plainPassword:
            'Le mot de passe doit contenir minimum 12 caractères, minimum 1 majuscule, 1 minuscule et 1 nombre ou caractère spécial.',
        multipleEmails:
            "Les emails doivent être séparés par un point virgule. ex : {'jean@dupont.com;robert@patrick.com;contact@entreprise.com'}",
        confirmPassword: "La confirmation du mot de passe n'est pas bon",
        fileMimeTypeImport: 'Formats acceptés : {values}',
        fileExtensionImport: 'Extensions acceptés : {values}',
        fileImageSize: 'Fichier trop lourd, 10Mo max',
        fileSize: 'Fichier trop lourd, {value} max',
        areaEnabledSmtpConfig: 'Une Configuration E-mailing doit être contribué pour pouvoir activer cet espace client',
        areaEnabledLogo: 'Un logo doit être contribué pour pouvoir activer cet espace client',
        areaEnabledVisioLogo: 'Un logo pour la visio doit être contribué pour pouvoir activer cet espace client',
        ftpUrl: 'Entrez une adresse valide',
        crontab: 'Format crontab non valide',
        requireProposedDateTimes: 'Vous devez sélectionner au minimum un créneau',
        inviteFieldValues: 'Le formulaire est invalide',
        emailOrPhone: 'Au minimum le champ email ou téléphone est nécessaire',
        start: 'Une date de début est requise',
        end: 'Une date de fin est requise',
        scheduleDateCheckIsDone: 'La vérification de la disponibilité du créneau est requise',
        scheduleDateCheckValidity: "Le créneau n'est pas disponible",
    },
    list: {
        actions: {
            'add-meeting': 'Créer un événement',
            'export-csv': 'Export CSV',
            'export-xlsx': 'Export XLS',
            'import-availabilities': 'Importer disponibilités',
            'no-bulk-actions': "Pas d'actions disponibles pour ces éléments sélectionnées",
            add: 'Ajouter',
            archive: 'Archiver',
            cancel: 'Annuler',
            configure: 'Configurer',
            connect: 'Se connecter',
            create_instant_video: 'Générer le lien visio',
            delete: 'Supprimer',
            disconnect: 'Se déconnecter',
            duplicate: 'Dupliquer',
            edit: 'Modifier',
            export: 'Exporter',
            fa_apenday_events_calendar: 'Calendrier qui expose les rdv Apenday',
            fa_apenday_hosted_calendar: 'Calendrier hébergé par Apenday',
            fa_cancel: 'Lien annuler',
            fa_download_ics: 'Télécharger fichier ICS',
            fa_survey: 'Lien sondage',
            fa_update: 'Lien modifier',
            fa_video_url_consultant: 'Lien vidéo consultant',
            fa_video_url_user: 'Lien vidéo utilisateur',
            fa_view: 'Voir',
            generate: 'Générer',
            impersonate: 'Se faire passer pour',
            import: 'Importer',
            infos: 'Informations',
            invite: 'Partager des créneaux',
            manage_availabilities: 'Modifier',
            miss: 'Manqué',
            notify_delay: 'Retard',
            occupancy: 'Occupation',
            publish: 'Publier',
            reassign: 'Réaffecter',
            regen: 'Régénérer',
            reset: 'Reset',
            schedule: 'Prendre un rendez-vous',
            separator: '',
            show: 'Afficher',
            terminate: 'Terminé',
            terminate_group: 'Terminer',
            test: 'Tester',
        },
        emptyList: 'Liste vide',
        loadMore: 'Afficher +',
        recordsCompleted: 'Vidéos en cours de rendu',
        pagination: {
            resultsCount: 'Pas de résultats | 1 résultat | {count} résultats',
            pagesCount: '0 pages | 1 page | {count} pages',
            itemsPerPage: 'Affichage par page',
        },
        loaderMessages: {
            update: 'Mise à jour en cours',
        },
        orderByDate: {
            asc: 'Plus ancien',
            desc: 'Plus récent',
        },
    },
    alerts: {
        actions: {
            cancel: 'Annuler',
            confirm: 'Confirmer',
            next: 'Continuer',
            understood: 'Compris',
        },
        crm: {
            disconnect: {
                header: 'Déconnexion',
                message: 'Êtes vous sûr(e) ?',
            },
        },
        default: {
            delete: {
                header: 'Suppression',
                message: 'Êtes vous sûr(e) ?',
            },
            cancel: {
                header: 'Annulation',
                message: 'Êtes vous sûr(e) ?',
            },
        },
        hub: {
            hubGroupDelete: {
                header: 'Attention',
                message: 'Si vous supprimez un groupe, cela réinitialisera les différentes options actuellement sélectionnées',
            },
            hubAreaDelete: {
                header: 'Attention',
                message: 'Si vous supprimez un espace client, cela réinitialisera les différentes options actuellement sélectionnées',
            },
            delete: {
                header: 'Suppression',
                message: 'Êtes vous sûr(e) ?',
            },
        },
        scheduledMeeting: {
            delete: {
                header: 'Suppression',
                message: 'Êtes vous sûr(e) ?',
            },
            cancel: {
                header: "Vous êtes sur le point d'annuler le rendez-vous",
                message:
                    'Êtes vous sûr de vouloir effectuer cette action irréversible ?\n' +
                    "(L'utilisateur sera prévenu de l'annulation de son rendez-vous en fonction des paramètres généraux du rendez-vous)",
            },
            miss: {
                header: 'RDV Manqué',
                message: 'Êtes vous sûr(e) ?',
            },
            terminate: {
                header: 'RDV Terminé',
                message: 'Êtes vous sûr(e) ?',
            },
            notify_delay: {
                header: 'Notification de retard',
                message: 'Souhaitez-vous envoyer une notification de retard ?',
            },
            notify_delay_success: {
                header: 'Succès',
                message: 'Notification envoyée avec succès',
            },
        },
        user: {
            regenKey: {
                header: 'Confirmation',
                message: 'Êtes vous sûr(e) de vouloir régénérer la clé ?',
            },
            delete: {
                header: 'Suppression',
                message: 'Êtes vous sûr(e) ?',
            },
        },
        area: {
            reset: {
                header: 'Réinitialisation',
                message: 'Êtes vous sûr(e) ?',
            },
            delete: {
                header: 'Suppression',
                message: 'Êtes vous sûr(e) ?',
            },
        },
        'external-auth': {
            error: {
                header: 'Échec connexion',
                message: `<p>Nous n'avons pas pu vous identifier. Plusieurs raisons possibles à cela:</p><br/>
                     <ul>
                          <li>Vous n'avez pas de compte <strong>actif</strong> dans l'application.</li>
                          <li>Le type de votre agenda n'est pas réglé sur <strong>google</strong> ou <strong>microsoft</strong> dans les réglages utilisateur.</li>
                      </ul>`,
            },
        },
        notifications: {
            error: {
                android: {
                    header: 'Notifications désactivées',
                    message: `<p>Nous n'avons pas pu activer les notifications.</p><br/>
                     <p>Vous devez les autoriser/activer manuellement dans les réglages de votre appareil</p>`,
                },
                ios: {
                    header: 'Notifications désactivées',
                    message: `<p>Nous n'avons pas pu activer les notifications.</p><br/>
                     <p>Vous devez les autoriser/activer manuellement dans les réglages de votre appareil</p>`,
                },
                web: {
                    header: 'Notifications désactivées',
                    message: `<p>Nous n'avons pas pu activer les notifications.</p><br/>
                     <p>Vous devez les autoriser/activer manuellement dans les réglages de votre navigateur</p>`,
                },
            },
            success: {
                header: 'Notifications',
                message: `<p>Les notifications sont autorisées</p><br/>
                     <p>Activez vos notifications depuis votre espace "Mon compte"</p>`,
                userButton: 'Mon compte',
            },
        },
        notFound: {
            header: 'Non trouvé',
            message: "Cet élément n'existe peut être pas ou plus sur le serveur",
        },
        app: {
            reload: {
                header: 'Mise à jour',
                message: "L'application a été mise à jour, la page va se recharger. Merci.",
            },
            needUpdate: {
                header: 'Attention',
                message:
                    "L'application dispose d'une mise à jour. Veuillez la télécharger sur les stores si ce n'est pas déjà fait automatiquement. Merci.",
            },
        },
        clipboard: {
            clipboardItemMissing: {
                message: "Désolé, cette fonctionnalité n'est pas disponible sur votre navigateur.",
            },
        },
    },
    toast: {
        default: {
            putSuccess: 'Sauvegardé avec succès',
            postSuccess: 'Création réussie',
            deleteSuccess: 'Supprimé avec succès',
            updateSuccess: 'Mise à jour réussie',
            cancelSuccess: 'Annulé avec succès',
            missSuccess: 'Mise à jour réussie',
            terminateSuccess: 'Mise à jour réussie',
        },
        scheduledMeeting: {
            putSuccess: 'Sauvegardé avec succès',
            postSuccess: 'Sauvegardé avec succès',
            deleteSuccess: 'Supprimé avec succès',
            updateSuccess: 'Mise à jour réussie',
            cancelSuccess: 'Le rendez-vous a bien été annulé.',
            missSuccess: 'Le rendez-vous a bien été classé comme manqué.',
            terminateSuccess: 'Le rendez-vous a bien été classé comme terminé.',
        },
        auth: {
            logout: 'Déconnexion',
        },
        smtpSettings: {
            test: {
                success: 'Test Smtp réussi avec succès',
                error: 'Échec du test Smtp : {message}',
            },
            delete: {
                success: 'Supprimé avec succès',
                error: 'Échec de la suppression',
            },
            postSuccess: 'Création réussie',
        },
        brevoApiSettings: {
            test: {
                success: 'Test Brevo réussi avec succès',
                error: 'Échec du test Brevo : {message}',
            },
            delete: {
                success: 'Supprimé avec succès',
                error: 'Échec de la suppression',
            },
            postSuccess: 'Création réussie',
        },
        smsSettings: {
            test: {
                error: 'Échec du test SMS : {message}',
                success: 'Test SMS réussi avec succès',
            },
            postSuccess: 'Création réussie',
            putSuccess: 'Sauvegardé avec succès',
        },
        form: {
            invalid: 'Formulaire invalide, vérifiez vos informations',
        },
    },
    common: {
        weeks: 'Semaines',
        days: 'Jours',
        hours: 'Heures',
        minutes: 'Minutes',
        day: 'Jour',
        hour: 'Heure',
        minute: 'Minute',
        day_s: 'Jour(s)',
        hour_s: 'Heure(s)',
        minute_s: 'Minute(s)',
        week_s: 'Semaine(s)',
        month_s: 'Mois',
        from: 'De',
        to: 'à',
        dateFrom: 'Du',
        dateTo: 'au',
        before: 'avant',
        yes: 'oui',
        no: 'non',
        copied: 'copié',
        errorServer: 'Il y a eu une erreur avec le serveur',
        networkError: 'Vous devez être connecté à internet pour utiliser cette application',
        showAllActivity: 'Afficher toute l’activité',
        chartNoData: 'Pas de données',
        total: 'Total {value}',
        clear: 'Effacer',
        maintenance:
            "La solution de prise de rendez-vous est en cours de maintenance et sera de nouveau accessible d'ici quelques minutes.",
        send: 'Envoyer',
        success: 'Succès',
        error: 'Erreur',
        skip: 'Passer',
        agendaView: 'Agenda',
        listView: 'Liste',
        pleaseWait: 'Veuillez patienter',
        launch: 'Lancer',
        retry: 'Ressayer',
        callBack: 'Être rappelé',
        enabled: 'Activé',
        disabled: 'Désactivé',
    },
    calendar: {
        views: {
            timeGridDay: 'Jour',
            timeGridWeek: 'Semaine',
            dayGridMonth: 'Mois',
        },
    },
    wysiwyg: {
        addVariable: 'Insérer une variable',
        variables: 'Variables',
    },
    assist: {
        closedSession: 'La session est terminée.',
    },
};
